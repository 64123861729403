/* eslint-disable max-lines */
import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';
import { plural } from '@cian/utils/lib/shared/plural';

import {
  IBuildingSchema,
  IBusinessShoppingCenterSchema,
  IKPSchema,
  ILandSchema,
  IOfferSchema,
  TBuildingClassType,
  ICoworking,
  TCategory,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { IOfferData } from 'shared/types/offerData';

import { getNewBusinessTitle, isBusinessSale } from './commercial';
import { SquareSymbol, TSquareSymbol } from './formatters';
import { getLastArrayItem } from './index';
import { FOfferType, getOfferType, isCommercial, isSuburban } from './offer_type';
import { AreaUnit, BuildingClassType, GarageType, PropertyType } from '../store/offer';
import { INewBuildingWithFeatures } from '../types/newobject/newobject';

interface IOfferTitleType {
  id: string;
  getTitle(): string;
}

const PIK_ID = 9;

function createOfferTitleType(id: string, getTitle: () => string): IOfferTitleType {
  return {
    getTitle,
    id,
  };
}

/* istanbul ignore next */
export function getPropertyType(offer: IOfferSchema): string {
  if (!offer.propertyType) {
    return '';
  }

  switch (offer.propertyType) {
    case PropertyType.Building:
      return 'Здание';
    case PropertyType.FreeAppointment:
      return 'Помещение свободного назначения';
    case PropertyType.Garage:
      return 'Гараж';
    case PropertyType.Industry:
      return 'Производство';
    case PropertyType.Land:
      return 'Земля';
    case PropertyType.Office:
      return 'Офис';
    case PropertyType.ShoppingArea:
      return 'Торговая площадь';
    case PropertyType.Warehouse:
      return 'Склад';
    default:
      return '';
  }
}

/* istanbul ignore next */
export function getGarageType(offer: IOfferSchema): string {
  if (!offer.garage) {
    return 'Гараж';
  }

  switch (offer.garage.type) {
    case GarageType.Box:
      return 'Бокс';
    case GarageType.Garage:
      return 'Гараж';
    case GarageType.ParkingPlace:
      return 'Машиноместо';
    default:
      return '';
  }
}

export function isPIKOffer(offer: IOfferSchema) {
  return Boolean(offer.buildersIds && offer.buildersIds.includes(PIK_ID));
}

export function getCommercialCustomTitle(offer: IOfferSchema, defaultTitle: string) {
  if (offer.specialty && Array.isArray(offer.specialty.types) && offer.specialty.types.length === 1) {
    if (offer.specialty.types[0] === 'carService') {
      return 'Автосервис';
    } else if (offer.specialty.types[0] === 'publicCatering') {
      return 'Общепит';
    } else if (offer.specialty.types[0] === 'domesticServices') {
      return 'Бытовые услуги';
    }
  }

  return defaultTitle;
}

/* istanbul ignore next */
export function getCommercialCustomTitleFn(offer: IOfferSchema, defaultTitle: string) {
  let getCommercialTitle = getCommercialCustomTitle;

  if (isBusinessSale(offer)) {
    getCommercialTitle = getNewBusinessTitle;
  }

  const result = getCommercialTitle(offer, defaultTitle);

  return () => result;
}

const coworkingOfferTypeTitle = {
  office: 'Отдельный офис',
  fixedWorkplace: 'Закреплённое рабочее место',
  flexibleWorkplace: 'Незакреплённое рабочее место',
  conferenceHall: 'Конференц-зал',
  meetingRoom: 'Переговорная комната',
};

export function getCommercialCoworkingTitle({ coworkingOfferType }: IOfferSchema, defaultTitle: string) {
  if (!coworkingOfferType) {
    return defaultTitle;
  }

  return coworkingOfferTypeTitle[coworkingOfferType] || defaultTitle;
}

export const getTitlePrefix = (offer: IOfferSchema) => {
  return offer.dealType.includes('rent') ? 'Сдается' : 'Продается';
};

/* istanbul ignore next */
export function getOfferTitle(offer: IOfferSchema) {
  const getFlatTitle = () => {
    const { isApartments, roomsCount, flatType } = offer;
    const multiRoomText = isApartments ? 'Многокомнатные' : 'Многокомнатная';
    const flatTypeText = isApartments ? 'Апартаменты' : 'Квартира';

    if (flatType === 'studio') {
      return isApartments ? 'Апартаменты-студия' : 'Студия';
    } else if (flatType === 'openPlan') {
      return `${flatTypeText} свободной планировки`;
    }

    return roomsCount >= 6
      ? `${multiRoomText} ${flatTypeText.toLowerCase()}`
      : `${roomsCount}-комн. ${flatTypeText.toLowerCase()}`;
  };

  const types = Array<IOfferTitleType>(
    createOfferTitleType('flat', getFlatTitle),
    createOfferTitleType('newBuildingFlat', getFlatTitle),
    createOfferTitleType('dailyFlat', getFlatTitle),
    createOfferTitleType(
      'room',
      () =>
        (offer.roomsForSaleCount &&
          offer.roomsForSaleCount > 1 &&
          `${offer.roomsForSaleCount} ${plural(offer.roomsForSaleCount, ['комната', 'комнаты', 'комнат'])}`) ||
        'Комната',
    ),
    createOfferTitleType('dailyRoom', () => 'Комната'),
    createOfferTitleType('dailyBed', () => 'Койко-место'),
    createOfferTitleType('bed', () => 'Койко-место'),
    createOfferTitleType('house', () => {
      return getTitleSuburban(offer, 'Дом');
    }),
    createOfferTitleType('cottage', () => {
      return getTitleSuburban(offer, 'Коттедж');
    }),
    createOfferTitleType('dailyHouse', () => {
      if (offer.isRentByParts && offer.rentByPartsDescription) {
        return `${offer.rentByPartsDescription} дома`;
      }

      return getTitleSuburban(offer, 'Дом');
    }),
    createOfferTitleType('townhouse', () => {
      return getTitleSuburban(offer, 'Таунхаус');
    }),
    createOfferTitleType('land', () => 'Участок'),
    createOfferTitleType('houseShare', () => {
      let houseInfo = 'дома';
      if (offer.building && offer.building.floorsCount) {
        houseInfo = `${offer.building.floorsCount}-этажного дома`;
      }

      return offer.shareAmount ? `${offer.shareAmount.replace('.', ',')} часть ${houseInfo}` : `Часть ${houseInfo}`;
    }),
    createOfferTitleType('flatShare', () => {
      return offer.shareAmount ? `${offer.shareAmount.replace('.', ',')} квартиры` : 'Доля';
    }),
    createOfferTitleType('office', () => getCommercialCoworkingTitle(offer, 'Офис')),
    createOfferTitleType('shoppingArea', () => 'Торговая площадь'),
    createOfferTitleType('warehouse', () => 'Склад'),
    createOfferTitleType('freeAppointmentObject', getCommercialCustomTitleFn(offer, 'Свободное назначение')),
    createOfferTitleType('industry', () => 'Производство'),
    createOfferTitleType('publicCatering', () => 'Общепит'),
    createOfferTitleType('business', getCommercialCustomTitleFn(offer, 'Готовый бизнес')),
    createOfferTitleType('building', () => 'Здание'),
    createOfferTitleType('domesticServices', () => 'Бытовые услуги'),
    createOfferTitleType('commercialLand', () => 'Коммерческая земля'),
    createOfferTitleType('carService', () => 'Автосервис'),
    createOfferTitleType('garage', () => {
      if (!offer.garage) {
        return 'Гараж';
      }

      switch (offer.garage.type) {
        case GarageType.Box:
          return 'Бокс';
        case GarageType.Garage:
          return 'Гараж';
        case GarageType.ParkingPlace:
          return 'Машиноместо';
        default:
          return '';
      }
    }),
  );

  const offerType = types.find(({ id }) => offer.category === `${id}Rent` || offer.category === `${id}Sale`);

  return (offerType && offerType.getTitle()) || '';
}

export function getTitleSuburban(offer: IOfferSchema, name: string) {
  if (offer.building && offer.building.floorsCount) {
    return `${offer.building.floorsCount}-этажный ${name.toLowerCase()}`;
  }

  return name;
}

/* istanbul ignore next */
export function getSquareInformation(offer: IOfferSchema): string {
  const offerType = getOfferType(offer);

  const totalArea = Number(offer.totalArea);
  const withTotalArea = applyTotalArea(totalArea);

  if (offerType === FOfferType.Land || offerType === FOfferType.CommercialLand) {
    const { land } = offer;

    if (land && land.area) {
      return `${numberToPrettyString(Number(land.area))} ${getLandAreaUnitType(land)}`;
    }
  }

  if (isCommercial(offerType) && !offer.coworkingOfferType) {
    const areas: Array<string | number | null> = [offer.minArea, offer.totalArea, offer.maxArea]
      .filter(Boolean)
      .sort((a, b) => Number(a) - Number(b));
    const validAreaFrom = discardDecimal(areas[0]);
    const validAreaTo = discardDecimal(getLastArrayItem(areas));
    const areasFrom = validAreaFrom ? `от ${numberToPrettyString(validAreaFrom)} ` : '';
    const areasTo = validAreaTo ? `до ${numberToPrettyString(validAreaTo)} ` : '';

    if (areas.length > 1) {
      return `${areasFrom}${areasTo}${SquareSymbol.SquareMeter}`;
    }
  }

  if (offerType === FOfferType.Bed && offer.roomArea) {
    return `${withTotalArea(numberToPrettyString(Number(offer.roomArea)))}`;
  }

  if (offerType === FOfferType.FlatShared) {
    const areas: Array<number> = [];
    if (Number(offer.livingArea)) {
      areas.push(Number(offer.livingArea));
    }

    if (Number(offer.kitchenArea)) {
      areas.push(Number(offer.kitchenArea));
    }

    return `${withTotalArea(areas.map(item => numberToPrettyString(Number(item))).join('/'))}`;
  }

  if (offerType === FOfferType.Room) {
    if (offer.allRoomsArea && offer.roomsForSaleCount && offer.roomsForSaleCount > 0) {
      return `${withTotalArea(offer.allRoomsArea)}`;
    }

    return `${withTotalArea(numberToPrettyString(Number(offer.roomArea)))}`;
  }

  if (totalArea) {
    return `${numberToPrettyString(Number(totalArea))} ${SquareSymbol.SquareMeter}`;
  }

  return '';
}

/* istanbul ignore next */
function applyTotalArea(totalArea: number) {
  return (value: number | string): string | number => {
    if (totalArea) {
      let result = '';
      result += numberToPrettyString(Number(totalArea));
      if (value) {
        result += `/${value}`;
      }
      result += ` ${SquareSymbol.SquareMeter}`;

      return result;
    }

    return `${value} ${SquareSymbol.SquareMeter}`;
  };
}

/* istanbul ignore next */
export function getLandAreaUnitType(land: ILandSchema | null): TSquareSymbol {
  if (!land) {
    return SquareSymbol.HundredSquareMeters;
  }

  return land.areaUnitType === AreaUnit.Hectare ? SquareSymbol.Hectare : SquareSymbol.HundredSquareMeters;
}

/**
 * По умолчанию выводит класс офиса уже в скобках
 */

/* istanbul ignore next */
export function getCommercialBuildingClass(
  building: IBuildingSchema | null,
  businessShoppingCenter: IBusinessShoppingCenterSchema | null,
  withoutBrackets: boolean = false,
): string {
  let buildingClassType: TBuildingClassType | undefined;
  let buildingClass: string | undefined;

  if (businessShoppingCenter && businessShoppingCenter.buildingClassType) {
    buildingClassType = businessShoppingCenter.buildingClassType;
  } else if (building && building.classType) {
    buildingClassType = building.classType;
  }

  // tslint:disable-next-line:switch-default
  switch (buildingClassType) {
    case BuildingClassType.A:
      buildingClass = 'А';
      break;
    case BuildingClassType.APlus:
      buildingClass = 'А+';
      break;
    case BuildingClassType.B:
      buildingClass = 'B';
      break;
    case BuildingClassType.BMinus:
      buildingClass = 'B-';
      break;
    case BuildingClassType.BPlus:
      buildingClass = 'B+';
      break;
    case BuildingClassType.C:
      buildingClass = 'C';
      break;
    case BuildingClassType.CPlus:
      buildingClass = 'C+';
      break;
    case BuildingClassType.D:
      buildingClass = 'D';
      break;
  }

  if (buildingClass) {
    return withoutBrackets ? buildingClass : ` (${buildingClass})`;
  }

  return '';
}

export type TParentType = 'JK' | 'Commercial' | 'KP' | 'Coworking';
export const ParentType = {
  Commercial: 'Commercial' as TParentType,
  JK: 'JK' as TParentType,
  KP: 'KP' as TParentType,
  Coworking: 'Coworking' as TParentType,
};

export interface IParent {
  name: string;
  url?: string;
  type: TParentType;
}

/* istanbul ignore next */
export function getParent(offerData: IOfferData): IParent | undefined {
  const {
    offer: { coworkingOfferType, coworking },
  } = offerData;
  const offerType = getOfferType(offerData.offer);

  if (coworkingOfferType) {
    return getCoworkingParent(coworking);
  }

  const isCommercialCardWithJK =
    isCommercial(offerType) &&
    [FOfferType.FreeAppointmentObject, FOfferType.Office, FOfferType.TradeArea, FOfferType.Warehouse].includes(
      offerType,
    ) &&
    offerData.newObject &&
    !offerData.businessShoppingCenter;

  if (isCommercialCardWithJK) {
    return getNewObjectParent(offerData.newObject as INewBuildingWithFeatures);
  }

  if (isCommercial(offerType)) {
    return getCommercialParent(offerData.businessShoppingCenter);
  }

  if (isSuburban(offerType)) {
    return getSuburbanParent(offerData.kp);
  }

  return getNewObjectParent(offerData.newObject as INewBuildingWithFeatures);
}

// генерит данные для ссылки на коттеджный поселок
/* istanbul ignore next */
export function getSuburbanParent(kp: IKPSchema | null): IParent | undefined {
  const parent: IParent | null = kp && {
    name: kp.name,
    type: ParentType.KP,
    url: kp.url,
  };

  return parent || undefined;
}

/* istanbul ignore next */
export function getNewObjectParent(newObject: INewBuildingWithFeatures | null): IParent | undefined {
  if (!newObject) {
    return undefined;
  }

  const parent: IParent = {
    name: newObject.name,
    type: ParentType.JK,
  };
  const newObjectUrl = newObject.fullUrlWithSubdomain || newObject.url;

  if (newObjectUrl) {
    parent.url = newObjectUrl;
  }

  return parent;
}

/* istanbul ignore next */
export function getCommercialParent(businessShoppingCenter: IBusinessShoppingCenterSchema | null): IParent | undefined {
  if (!businessShoppingCenter) {
    return undefined;
  }

  const parent: IParent = {
    name: '',
    type: ParentType.Commercial,
  };

  if (businessShoppingCenter.useParentName) {
    if (businessShoppingCenter.parentName) {
      parent.name += businessShoppingCenter.parentName;

      if (businessShoppingCenter.name) {
        parent.name += ` (${businessShoppingCenter.name})`;
      }
    } else if (businessShoppingCenter.name) {
      parent.name += businessShoppingCenter.name;
    }
  } else if (businessShoppingCenter.name) {
    parent.name = businessShoppingCenter.name;
  }

  if (businessShoppingCenter.url) {
    parent.url = businessShoppingCenter.url;
  } else if (businessShoppingCenter.parentUrl) {
    parent.url = businessShoppingCenter.parentUrl;
  }

  return parent.name ? parent : undefined;
}

export function getCoworkingParent(coworking: ICoworking | undefined) {
  if (!coworking) {
    return undefined;
  }

  const parent: IParent = {
    name: coworking.name || '',
    type: ParentType.Coworking,
    url: coworking.url,
  };

  return parent.name ? parent : undefined;
}

export const buildingStatusType: {
  [index: string]: string;
} = {
  newBuilding: 'Новостройка',
  operational: 'Действующее',
  project: 'Проект',
  secondary: 'Вторичка',
  underConstruction: 'Строящееся',
};

/* istanbul ignore next */
export function getStatusType(
  building: IBuildingSchema | null,
  BSC: IBusinessShoppingCenterSchema | null,
): string | undefined {
  if (building && building.statusType && buildingStatusType[building.statusType]) {
    return buildingStatusType[building.statusType];
  } else if (BSC && BSC && BSC.buildingStatusType) {
    return buildingStatusType[BSC.buildingStatusType];
  }

  return undefined;
}

export const landStatus: {
  [index: string]: string;
} = {
  owned: 'В собственности',
  rent: 'В аренде',
};

export const inputType: {
  [index: string]: string;
} = {
  commonFromTheStreet: 'Общий с улицы',
  commonFromTheYard: 'Общий со двора',
  separateFromTheStreet: 'Отдельный с улицы',
  separateFromTheYard: 'Отдельный со двора',
};

export const buildingTypes: {
  [index: string]: string;
} = {
  administrativeBuilding: 'Административное здание',
  businessCenter: 'Бизнес-центр',
  businessCenter2: 'Деловой центр',
  businessPark: 'Бизнес-парк',
  businessQuarter: 'Бизнес-квартал',
  free: 'Свободное',
  freeAppointmentObject: 'Объект свободного назначения',
  industrialComplex: 'Производственный комплекс',
  industrialPark: 'Индустриальный парк',
  industrialSite: 'Промплощадка',
  industrialWarehouseComplex: 'Производственно-складской комплекс',
  logisticsCenter: 'Логистический центр',
  logisticsComplex: 'Логистический комплекс',
  mansion: 'Особняк',
  manufactureBuilding: 'Производственное здание',
  manufacturingFacility: 'Производственный цех',
  modular: 'Модульное здание',
  multifunctionalComplex: 'Многофункциональный комплекс',
  officeAndHotelComplex: 'Офисно-гостиничный комплекс',
  officeAndResidentialComplex: 'Офисно-жилой комплекс',
  officeAndWarehouse: 'Офисно-складское',
  officeAndWarehouseComplex: 'Офисно-складской комплекс',
  officeBuilding: 'Офисное здание',
  officeIndustrialComplex: 'Офисно-производственный комплекс',
  old: 'Старый фонд',
  outlet: 'Аутлет',
  propertyComplex: 'Имущественный комплекс',
  residentialComplex: 'Жилой комплекс',
  residentialFund: 'Жилой фонд',
  residentialHouse: 'Жилой дом',
  shoppingAndBusinessComplex: 'Торгово-деловой комплекс',
  shoppingAndCommunityCenter: 'Торгово-общественный центр',
  shoppingAndEntertainmentCenter: 'Торгово-развлекательный центр',
  shoppingCenter: 'Торговый центр',
  specializedShoppingCenter: 'Специализированный торговый центр',
  standaloneBuilding: 'Отдельно стоящее здание',
  technopark: 'Технопарк',
  tradingOfficeComplex: 'Торгово-офисный комплекс',
  uninhabitedFund: 'Нежилой фонд',
  warehouse: 'Склад',
  warehouseComplex: 'Складской комплекс',
  shoppingAndWarehouseComplex: 'Торгово-складской комплекс',
};

export const buildingTypePrepositional: {
  [index: string]: string;
} = {
  administrativeBuilding: 'административном здании',
  businessCenter: 'бизнес-центре',
  businessCenter2: 'деловом центре',
  businessHouse: 'деловом доме',
  businessPark: 'бизнес-парке',
  businessQuarter: 'бизнес-квартале',
  businessQuarter2: 'деловом квартале',
  free: 'свободном здании',
  freeAppointmentObject: 'объекте свободного назначения',
  industrialComplex: 'производственном комплексе',
  industrialPark: 'индустриальном парке',
  industrialSite: 'промплощадке',
  industrialWarehouseComplex: 'производственно-складском комплексе',
  logisticsCenter: 'логистическом центре',
  logisticsComplex: 'логистическом комплексе',
  mansion: 'особняке',
  manufactureBuilding: 'производственном здании',
  manufacturingFacility: 'производственном цехе',
  modular: 'модульном здании',
  multifunctionalComplex: 'многофункциональном комплексе',
  officeAndHotelComplex: 'офисно-гостиничном комплексе',
  officeAndResidentialComplex: 'офисно-жилом комплексе',
  officeAndWarehouse: 'офисно-складском здании',
  officeAndWarehouseComplex: 'офисно-складском комплексе',
  officeBuilding: 'офисном здании',
  officeCenter: 'офисном центре',
  officeComplex: 'офисном комплексе',
  officeIndustrialComplex: 'офисно-производственом комплексе',
  officeQuarter: 'офисном квартале',
  outlet: 'аутлете',
  propertyComplex: 'имущественном комплексе',
  residentialComplex: 'жилом комплексе',
  residentialHouse: 'жилом доме',
  shoppingAndBusinessComplex: 'торгово-деловом комплексе',
  shoppingAndCommunityCenter: 'торгово-общественном центре',
  shoppingAndEntertainmentCenter: 'торгово-развлекательном центре',
  shoppingCenter: 'торговом центре',
  shoppingComplex: 'торговом комплексе',
  specializedShoppingCenter: 'специализированном торговом центре',
  standaloneBuilding: 'здании',
  technopark: 'технопарке',
  tradeAndExhibitionComplex: 'торгово-выставочном комплексе',
  tradingHouse: 'торговом доме',
  tradingOfficeComplex: 'торгово-офисном комплексе',
  warehouse: 'складе',
  warehouseComplex: 'складском комплексе',
  shoppingAndWarehouseComplex: 'торгово-складском комплексе',
};

/* istanbul ignore next */
export function getLandArea(offer: IOfferSchema, BSC: IBusinessShoppingCenterSchema | null): string | undefined {
  if (offer.land && offer.land.area) {
    return `${numberToPrettyString(Number(offer.land.area))} ${getLandAreaUnitType(offer.land)}`;
  }

  if (BSC && BSC.land && BSC.land.area) {
    return `${numberToPrettyString(Number(BSC.land.area))} ${SquareSymbol.Hectare}`;
  }

  return undefined;
}

/* istanbul ignore next */
export function getLandStatusType(offer: IOfferSchema, BSC: IBusinessShoppingCenterSchema | null): string | undefined {
  if (offer.land && offer.land.type) {
    return landStatus[offer.land.type];
  }

  if (BSC && BSC.land && BSC.land.ownershipType) {
    return landStatus[BSC.land.ownershipType];
  }

  return undefined;
}

/* istanbul ignore next */
export function getBuildingType(
  building: IBuildingSchema | null,
  BSC: IBusinessShoppingCenterSchema | null,
): string | undefined {
  if (building && building.type) {
    return buildingTypes[building.type];
  }

  if (BSC && BSC.buildingType) {
    return buildingTypes[BSC.buildingType];
  }

  return undefined;
}

/* istanbul ignore next */
export function getOpeningHours(
  building: IBuildingSchema | null,
  BSC: IBusinessShoppingCenterSchema | null,
): string | undefined {
  let result = '';

  if (building && building.openingHours) {
    if (building.openingHours.type === 'roundTheClock') {
      result = 'Круглосуточно';
    }

    if (building.openingHours.type === 'specific') {
      if (building.openingHours.from) {
        result += `с ${building.openingHours.from} `;
      }

      if (building.openingHours.to) {
        result += `до ${building.openingHours.to}`;
      }
    }
  }

  if (!result && BSC && BSC.openingHours) {
    if (BSC.openingHours.type === 'roundTheClock') {
      result = 'Круглосуточно';
    }

    if (BSC.openingHours.type === 'specific') {
      if (BSC.openingHours.from) {
        result += `с ${BSC.openingHours.from} `;
      }

      if (BSC.openingHours.to) {
        result += `до ${BSC.openingHours.to}`;
      }
    }
  }

  return result;
}

/**
 * Возвращает строку вида - июня 2015
 */
export function mapAvailableFromToLocaleString(availableFrom: string): string | undefined {
  const availableDate = new Date(availableFrom);

  const monthNumberMapToString: { [index: number]: string } = {
    0: 'янв',
    1: 'фев',
    2: 'марта',
    3: 'апр',
    4: 'мая',
    5: 'июня',
    6: 'июля',
    7: 'авг',
    8: 'сен',
    9: 'окт',
    10: 'нбр',
    11: 'дек',
  };

  const monthName = monthNumberMapToString[availableDate.getMonth()];
  const year = availableDate.getFullYear();

  return monthName && year ? `${monthName} ${year}` : undefined;
}

/**
 * Возвращает строки типа:
 *  от 15 до 50 м2
 *  50 м2
 */

/* istanbul ignore next */
export function getCommercialArea(minArea: string | null, maxArea: string | null, totalArea: string | null): string {
  const minValue = discardDecimal(minArea);
  const maxValue = discardDecimal(maxArea);
  const totalValue = discardDecimal(totalArea);

  if (minValue && maxValue) {
    return `от ${numberToPrettyString(minValue)} до ${numberToPrettyString(maxValue)} м\u00B2`;
  }

  if (minValue && !maxValue && totalValue) {
    return `от ${numberToPrettyString(minValue)} до ${numberToPrettyString(totalValue)} м\u00B2`;
  }

  if (!minValue && maxValue && !totalValue) {
    return `${numberToPrettyString(maxValue)} м\u00B2`;
  }

  if (!minValue && !maxValue && totalValue) {
    return `${numberToPrettyString(totalValue)} м\u00B2`;
  }

  return '';
}

/**
 * Возвращает строки типа:
 * 15 - 50 м2
 * от 50 м2 (для случаев, когда minArea и maxArea равны)
 */
export function getCommercialAreaForMultiAds(minArea: string | null, maxArea: string | null): string {
  const minValue = discardDecimal(minArea);
  const maxValue = discardDecimal(maxArea);

  if (minValue && maxValue) {
    if (minValue === maxValue) {
      return `от ${numberToPrettyString(minValue)} м\u00B2`;
    }

    return `${numberToPrettyString(minValue)} \u2013 ${numberToPrettyString(maxValue)} м\u00B2`;
  }

  return '';
}

/**
 * Если по какой-то причине число вернулось в виде - 14.0 функция отбрасывает лишний ноль
 * и округляет до 2 знаков после запятой.
 */

/* istanbul ignore next */
export function discardDecimal(value?: string | number | null, length: number = 2): number | null {
  const valueLikeNumber = Number(value);

  if (!value || isNaN(valueLikeNumber)) {
    return null;
  }

  return Number(valueLikeNumber.toFixed(length));
}

export const isDailyrent = (offerCategory: TCategory) => {
  return ['dailyHouseRent', 'dailyBedRent', 'dailyFlatRent', 'dailyRoomRent'].includes(offerCategory);
};
