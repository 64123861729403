import {
  IOfferSchema,
  IPhoneSchema,
  IUndergroundInfoSchema,
  TCurrency,
  TQuarter,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { FDealType, getDealType } from './deal_type';
import { Currency } from '../store/offer';

export const CurrencySymbol = {
  Eur: '\u20ac',
  Rur: '\u20bd',
  Usd: '\u0024',
};

export type TSquareSymbol = 'га' | 'сот.' | 'м\u00B2';

export const SquareSymbol = {
  Hectare: 'га' as TSquareSymbol,
  HundredSquareMeters: 'сот.' as TSquareSymbol,
  SquareMeter: 'м\u00B2' as TSquareSymbol,
};

export function getCurrencySymbol(currency: TCurrency): string | null {
  switch (currency) {
    case Currency.Rur:
      return CurrencySymbol.Rur;
    case Currency.Eur:
      return CurrencySymbol.Eur;
    case Currency.Usd:
      return CurrencySymbol.Usd;
    default:
      return null;
  }
}

export function getPriceRentTimeSuffix(offer: IOfferSchema) {
  const dealType = getDealType(offer);

  if (dealType === FDealType.RentLongterm) {
    return '/мес.';
  }

  if (dealType === FDealType.RentDaily) {
    return '/сут.';
  }

  return '';
}

export function getFormattedPhoneNumber(phone: IPhoneSchema): string {
  let formattedPhone = '';

  if (phone.countryCode) {
    if (!phone.countryCode.startsWith('+')) {
      formattedPhone += `+${phone.countryCode} `;
    } else {
      formattedPhone += `${phone.countryCode} `;
    }
  }

  if (!phone.number) {
    return '';
  }

  if (phone.number.length !== 10) {
    return `${formattedPhone} ${phone.number}`;
  }

  formattedPhone += `${phone.number.substr(0, 3)} `;
  formattedPhone += `${phone.number.substr(3, 3)}-${phone.number.substr(6, 2)}-${phone.number.substr(8, 2)}`;

  return formattedPhone;
}

export function getNewbuildingsListQueryStringUrl(qs: string): string {
  return `/newobjects/list/?${qs}`;
}

export function getUndergroundTime(underground: IUndergroundInfoSchema): string {
  return ` ${underground.travelTime || '<1'} мин. ${underground.travelType === 'walk' ? 'пешком' : 'на транспорте'}`;
}

export function getConstructionInfo(underground: IUndergroundInfoSchema): string {
  const { underConstruction, releaseYear } = underground;

  return underConstruction ? ` откроется в ${releaseYear}` : '';
}

export const Quarters: quartersType = {
  first: 1,
  fourth: 4,
  second: 2,
  third: 3,
};

type quartersType = { [index in TQuarter]?: number };

export function getFormattedQuarter(quarter?: TQuarter | null) {
  return quarter ? `${Quarters[quarter]} кв.` : '';
}

/**
 * Заменяет все пробелы в строке Unicode символом 'NO-BREAK SPACE' (U+00A0)
 */
export function withNoBreakSpaces(str: string): string {
  return str.replace(/\s/g, '\u00a0');
}

/**
 * Заменяет все Unicode символы 'NO-BREAK SPACE' (U+00A0) в строке на пробелы
 */
export function withoutNoBreakSpaces(str: string): string {
  return str.replace(/\u00a0/g, ' ');
}
