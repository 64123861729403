import { UXFB_COOKIE_KEY } from 'shared/constants/uxfb';
import { getUxfbVisitedOffersLimit, parseUxfbVisitedOffers } from 'shared/utils/uxfb';

import { getDynamicCalltrackingSiteBlockId } from '../../selectors/getDynamicCalltrackingSiteBlockId';
import { getExternalTour } from '../../selectors/newObject/getExternalTour';
import { virtualTourKeySelector } from '../../selectors/newObject/getTour';
import { showSuburbanBuildingsBanners } from '../../selectors/showSuburbanBuildingsBanners';
import { IThunkActionCreator } from '../../store';
import { setEbcInfo } from '../../store/ebc';
import { setTrackingData } from '../../store/tracking';
import { TBinaryValue } from '../../types';
import { createEbcInfo } from '../../utils/tracking/createEbcInfo';
import { createPageTrackingData } from '../../utils/tracking/createPageTrackingData';
import { createUserTrackingData } from '../../utils/tracking/createUserTrackingData';
import { getBookedFromDeveloper } from '../../utils/tracking/getBookedFromDeveloper';
import { getCplPageExtra } from '../../utils/tracking/getCplPageExtra';
import { getPriceExtra } from '../../utils/tracking/getPriceExtra';

export const prepareEbcAction = (
  cookies: Record<string, string> = {},
  queryObject?: Record<string, string | null>,
): IThunkActionCreator => {
  return (dispatch, getState, { config }) => {
    const state = getState();
    const {
      abGroup,
      offerData: { offer, user, agent, breadcrumbs },
    } = state;

    const uxfbVisitedOffersLimit = getUxfbVisitedOffersLimit(config);
    const uxfbVisitedOffers = parseUxfbVisitedOffers(cookies[UXFB_COOKIE_KEY]);
    const sendUxfbFlag =
      uxfbVisitedOffers.length === uxfbVisitedOffersLimit - 1 && !uxfbVisitedOffers.includes(offer.id);

    const siteBlockId = getDynamicCalltrackingSiteBlockId(state);
    const priceExtra = getPriceExtra(state);
    const extraCpl = getCplPageExtra(offer, siteBlockId);
    const has3dTour = Boolean(virtualTourKeySelector(state));
    const breadCrumbs = breadcrumbs ? breadcrumbs.map(bcInfo => bcInfo.title) : [];
    const showSuburbanAdfoxBlock = showSuburbanBuildingsBanners(state);
    const isBookedFromDeveloper = getBookedFromDeveloper(state);

    // для доп поля в аналитику, если пользователь увидит блок с внешним туром
    const isExternalTourEnabled = !!config.get<boolean>('external_tour_enabled.Enabled');
    const hasExternal3dTour = Boolean(getExternalTour(state));

    const extra = {
      ...extraCpl,
      ...priceExtra,
      is_booked: isBookedFromDeveloper,
      category: offer.category,
      coworkingOfferType: offer.coworkingOfferType || undefined,
      adfox: { status: Number(showSuburbanAdfoxBlock) as TBinaryValue },
      pageTypeSource: queryObject?.source,
      listingTypeSource: queryObject?.listingType,
    };

    if (hasExternal3dTour && isExternalTourEnabled) {
      extra['type'] = '3dtour_enabled_1';
    }

    if (has3dTour) {
      extra['3dtour_enabled'] = 1;
    }

    const pageTrackingData = createPageTrackingData({ agent, offer, breadCrumbs, extra });
    const userTrackingData = createUserTrackingData({ abGroup, user, sendUxfbFlag });
    const ebcInfo = createEbcInfo({ abGroup, breadCrumbs, offerID: offer.id, user });

    dispatch(setTrackingData(userTrackingData, pageTrackingData));
    dispatch(setEbcInfo(ebcInfo));
  };
};
