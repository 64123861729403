import { getGlobalEventsLogs } from '@cian/events-log';

import { TOPIC_USER } from 'shared/eventsLogs/topics';

interface IViewData {
  headingType: string | undefined;
}

export interface ICallAuthenticationPopupParameters {
  viewData?: IViewData;
}

export function callAuthenticationPopup(parameters?: ICallAuthenticationPopupParameters) {
  const globalLog = getGlobalEventsLogs();

  globalLog.produce(TOPIC_USER, {
    type: 'authenticate',
    value: {
      autoRefresh: false,
      viewData: parameters?.viewData || {},
    },
  });
}
