import { Button, FormField, IconBullet16, Input, LinkButton, UIHeading4, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './BuyForm.css';
import { CONTENT_LIST, STATUS_MESSAGES } from './constants';
import { useFormValidation } from './hooks';
import { IBuyFormProps } from './types';
import { EBuyAnalyticsFormStatus } from '../../types';
import { StatusBar } from '../StatusBar';

export const BuyForm: React.FC<IBuyFormProps> = ({
  buildingName,
  buildingAddress,
  userEmail,
  reportPrice,
  status,
  freeReportData,
  legalDocsUrl,
  isLegalDocsUrlEnabled,
  onSubmit,
  onTrackClickEvent,
}) => {
  const { name, date, url } = freeReportData;
  const [email, setEmail] = React.useState<string>(userEmail);

  const { hasError, errorMessage, validateForm, resetError } = useFormValidation();

  const handleChangeEmail = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    resetError();
    setEmail(value);
  };

  const onSubmitForm = React.useCallback(() => {
    const hasValidationError = validateForm(email);

    if (hasValidationError) {
      return;
    }
    onSubmit(email);
  }, [onSubmit, validateForm, email]);

  const isFormLoading = status === EBuyAnalyticsFormStatus.Pending || status === EBuyAnalyticsFormStatus.Sending;
  const hasErrorStatus = status === EBuyAnalyticsFormStatus.Error || status === EBuyAnalyticsFormStatus.UserError;
  const isStatusBarShown = isFormLoading || hasErrorStatus;
  const isButtonDisabled = isFormLoading || status === EBuyAnalyticsFormStatus.UserError;

  return (
    <div className={styles['container']}>
      <div className={styles['form-wrapper']}>
        <div className={styles['description']}>
          {!!buildingName && <UIText1>{buildingName}.</UIText1>}
          {!!buildingAddress && <UIText1>{buildingAddress}.</UIText1>}
        </div>
        <div className={styles['form']}>
          <FormField label="Оставьте email, на него придёт отчёт" errorMessage={errorMessage}>
            <Input
              value={email}
              size="M"
              placeholder="Email"
              invalid={hasError}
              onChange={handleChangeEmail}
              disabled={isFormLoading}
            />
          </FormField>
          <div className={styles['form-buttons']}>
            <UIHeading4>Итого: {reportPrice}</UIHeading4>
            <Button theme="fill_primary" onClick={onSubmitForm} disabled={isButtonDisabled}>
              Перейти к оплате
            </Button>
          </div>
        </div>
      </div>
      <div className={styles['info-wrapper']}>
        <div className={styles['info-title']}>
          <UIHeading4>Что входит в PDF-отчёт</UIHeading4>
        </div>
        <div className={styles['info-list']}>
          {CONTENT_LIST.map(item => {
            return (
              <div key={item} className={styles['info-item']}>
                <div className={styles['bullet-wrapper']}>
                  <IconBullet16 color="gray_icons_100" />
                </div>
                <UIText1>{item}</UIText1>
              </div>
            );
          })}
        </div>
        <div className={styles['free-report-title']}>
          <UIHeading4>Бесплатный пример отчёта</UIHeading4>
        </div>
        <div className={styles['free-report-wrapper']}>
          <div className={styles['free-report-building']}>
            <UIText1>{name}</UIText1>
            <UIText1 color="gray60_100">{date}</UIText1>
          </div>
          <LinkButton theme="fill_secondary" size="XS" href={url} target="_blank" download onClick={onTrackClickEvent}>
            Скачать пример
          </LinkButton>
        </div>
        {
          /**
           * @todo Удалить проверку фичатоггла buyAnalyticsLegalDocsUrl.iap.Enabled
           * @description Данный функционал появился в задаче CD-137590, будет удалён в задаче CD-137656
           */
          isLegalDocsUrlEnabled && (
            <div className={styles['legal-docs-wrapper']}>
              <UIText1>
                Оплачивая услугу, вы принимаете условия{' '}
                <a href={legalDocsUrl} target="_blank" className={styles['link']} rel="noreferrer">
                  оферты
                </a>
              </UIText1>
            </div>
          )
        }
      </div>
      {isStatusBarShown && (
        <div className={styles['status-bar-wrapper']}>
          <StatusBar statusMessage={STATUS_MESSAGES[status]} hasError={hasErrorStatus} hasSpinner={isFormLoading} />
        </div>
      )}
    </div>
  );
};

BuyForm.displayName = 'BuyForm';
