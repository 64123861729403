/* eslint-disable max-lines */

import { favoritesAssignWidgetReducer, IFavoritesAssignWidgetState } from '@cian/favorites-assign-widget';
import { combineReducers, configureStore, Reducer, Store, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';

import { bestPlaceModalReducer } from 'shared/reducers/bestPlaceReportModal/reducer';
import { buildersProjectsLocationsReducer } from 'shared/reducers/buildersProjectsLocations';
import { dailyrentActionTypeReducer, TDailyrentActionTypeState } from 'shared/reducers/dailyrentActionType';
import { dailyrentBookingCalendarReducer } from 'shared/reducers/dailyrentBookingCalendar';
import { reviewsGoToAppModalReducer } from 'shared/reducers/reviewsGoToAppModal';
import { valuationTrapsReducer } from 'shared/reducers/valuationTraps';
import { IDailyrentBookingCalendarState } from 'shared/store/dailyrentBookingCalendar/types';
import { IValuationTrapsState } from 'shared/store/valuationTraps';
import { IBestPlaceAnalyticsState } from 'shared/types/bestPlaceAnalytics';
import { IBestPlaceReportModalState } from 'shared/types/bestPlaceReportModal';
import { INewbuildingBlackFridaySettings } from 'shared/types/newbuildingBlackFridaySettings';
import { IReviewsGoToAppModalState } from 'shared/types/reviewsGoToAppModal';
import { ISuburbanProjectSuggest } from 'shared/types/suburbanBuildersProjects';

import { abUseExperimentsReducer } from './abUseExperiments';
import { adfoxOfferReducer } from './adfoxParams/reducer/adfoxOfferReducer';
import { banksListReducer } from './banksList';
import { commentsReducer, IComments } from './comments';
import { complaintPopupOpenReducer } from './complaintPopupOpen';
import { IState as IDealRentState, reducer as dealRent } from './deal-rent';
import { ebcReducer, IEbcState } from './ebc';
import { favoriteMotivationReducer, IFavoriteMotivationState } from './favoriteMotivation';
import { galleryReducer, IGalleryData } from './gallery/';
import { genplanReducer } from './genplan';
import { hideOfferReducer, IHideOffer } from './hide_offer';
import { homeownerReducer } from './homeowner';
import { infrastructureCategoriesReducer } from './infrastructure-categories';
import { infrastructureMapReducer, TInfrastructureMapState } from './infrastructure-map';
import { knAuctionCompaniesReducer } from './knAuctionCompanies/reducer';
import { ISimilarOffersData, IViewedData, mapSimilarReducer, markViewedReducer } from './map_similar';
import { IMapTabs, mapTabsReducer } from './map_tabs';
import { IMortgageState, mortgageStateReducer } from './mortgage';
import { IMortgageGalleryState, mortgageGalleryStateReducer } from './mortgageGallery';
import { asideReducer, IAside } from './navigation';
import { newbuildingChatReducer } from './newbuildingChat';
import { notAvailablePopupOpenReducer } from './notAvailablePopupOpen';
import { offerReducer } from './offer';
import { comparisonNotificationReducer } from './offerComparisonNotifications';
import { IOffersHistoryState } from './offerHistory';
import { IOfferValuationState } from './offerValuation';
import { offerValuationReducer } from './offerValuation/reducer';
import { IOfferStatsPopupState, offerStatsPopupReducer } from './offer_stats_popup';
import { phoneCollapseReducer } from './phone_collapse';
import { ILegendState, legendModalReducer } from './popups/legend';
import { IPrintModal, printModalReducer } from './popups/print';
import { IReclaimModalState, reclaimModalReducer } from './popups/reclaim';
import { IRemoveState, removeModalReducer } from './popups/remove';
import { pushSubscribedReducer, TPushSubscribed } from './push_subscription';
import { recommendedVillagesReducer } from './recommendedVillages';
import { IState as IRenterFormState, reducer as renterForm } from './renterForm';
import { rosreestrReducer } from './rosreestr';
import { rsReducer } from './rs/reducer';
import { IRSState } from './rs/types';
import { ISeoLinksState, seoLinksReducer } from './seo_links';
import { ISimilarState, similarOffersReducer } from './similar';
import { specialPromoInfoReducer, TSpecialPromoInfoState } from './special_promo_info';
import {
  ISubscriptionPriceChangesPopup,
  subscriptionPriceChangesPopupReducer,
} from './subscription_price_changes_popup';
import { suburbanBuildersProjectsReducer } from './suburbanBuildersProjects';
import { ITrackingReducerState, trackingReducer } from './tracking';
import { userDataReducer } from './user';
import { villagePromotionsReducer } from './villagePromotions';
import { IWidgetsMarkupState, widgetMarkupReducer } from './widgetsMarkup/reducers';
import { feedbackReducer, IFeedback } from '../FeedbackExperiment/reducers/feedback';
import { bestPlaceAnalyticsReducer } from '../reducers/bestPlaceAnalytics';
import { buyAnalyticsModalReducer } from '../reducers/buyAnalyticsModal';
import { chatReducer, IOfferChatsState } from '../reducers/chat';
import { flatTourBookingReducer } from '../reducers/flatTourBookingReducer';
import { newbuildingBookedFromDeveloperReducer } from '../reducers/newbuildingBookedFromDeveloper';
import { offersHistoryReducer } from '../reducers/offersHistory';
import { phoneWarningReducer } from '../reducers/phoneWarning';
import { ECategory } from '../repositories/infrastructure-caching/entities/infrastructure/CategoryItemsSchema';
import { IGenplanScheme } from '../types';
import { IAbUseExperiment } from '../types/abUseExperiments';
import { TAdfoxRequestedOffer } from '../types/adfox';
import { IApplicationContext } from '../types/applicationContext';
import { IBankListState } from '../types/banksList';
import { IBuyAnalyticsModalState } from '../types/buyAnalyticsModal';
import { IComparisonNotification } from '../types/comparisonNotification';
import { IFlatTourBookingState } from '../types/flatTourBooking';
import { INewbuildingBookedFromDeveloperState } from '../types/newbuildingBookedFromDeveloper';
import { INewbuildingChat } from '../types/newbuildingChat';
import { INewbuildingConsultant } from '../types/newbuildingConsultant';
import { IOfferData } from '../types/offerData';
import { IPhoneWarningState } from '../types/phoneWarning';
import { IRecommendedVillages } from '../types/recommendedVillages';
import { IRosreestrState } from '../types/rosreestr';
import { IVillagePromotionsData } from '../types/villagePromotions';

import type { RootAction } from '../types/redux/RootAction';

export interface IPageData {
  subdomain: string;
  dealType: 'sale' | 'rent';
  offerType: 'flat' | 'suburban' | 'commercial';
  offerId: number;
}

export interface IUtilitiesTermsSettings {
  isUtilitiesTermsEnabled: boolean;
  useIncludeUtilitiesTermsRegions: boolean;
  excludeUtilitiesTermsRegions: number[];
  includeUtilitiesTermsRegions: number[];
}

export interface IFeatureToggleState {
  /** Настройки коммунальных услуг в аренде жилой */
  utilitiesTermsSettings: IUtilitiesTermsSettings;
  newbuildingBlackFridaySettings: INewbuildingBlackFridaySettings;
  isFavoritesFolderEnabled: boolean;
  /** Флаг показа кнопки покупки аналитического отчета по зданию */
  isBuyAnalyticsButtonEnabled: boolean;
  /** Флаг показа ссылки на оферту для покупки аналитического отчета по зданию */
  isLegalDocsUrlForCommercialReportsEnabled: boolean;
  isCommercialNewVatTextEnabled: boolean;
  /** Баннер сервиса "Ипотечный брокер" (Циан.Ипотека для профи) включен? */
  isMortgageBrokerBannerEnabled: boolean;
  /**
   * @description Новый ипотечный калькулятор новостроек в виде микрофронта NB-1419
   */
  newbuildingMortgageCalculatorMicrofrontEnabled: boolean;
}

export interface IState {
  abGroup: number;
  abUseExperiments: IAbUseExperiment[];
  aside: IAside;
  captchaSiteKey: string;
  captchaSpamSiteKey: string;
  commentsData: IComments;
  complaintPopupOpen: boolean;
  notAvailablePopupOpen: boolean;
  favoriteMotivation: IFavoriteMotivationState;
  feedback: IFeedback;
  gallery: IGalleryData;
  hideOffer: IHideOffer;
  isStartChatWidgetInDropdown: boolean;
  isCountrysideOffersBuilderBadgeEnabled: boolean;
  legend: ILegendState;
  mapSimilar: ISimilarOffersData;
  mapTabs: IMapTabs;
  mortgage: IMortgageState;
  mortgageGallery: IMortgageGalleryState;
  offerChat: IOfferChatsState;
  offerData: IOfferData;
  promotionsVillageData: IVillagePromotionsData;
  offerStatsPopup: IOfferStatsPopupState;
  pageData: IPageData;
  phoneCollapse: boolean;
  phoneWarning: IPhoneWarningState;
  printModal: IPrintModal;
  reclaimModal: IReclaimModalState;
  remove: IRemoveState;
  seoLinks: ISeoLinksState;
  serviceBaseUrl: string;
  similarOffers: ISimilarState;
  specialPromoInfo: TSpecialPromoInfoState;
  subscriptionPriceChangesPopup: ISubscriptionPriceChangesPopup;
  tracking: ITrackingReducerState;
  viewedSimilar: IViewedData;
  pushSubscribed: TPushSubscribed;
  newbuildingBookedFromDeveloper: INewbuildingBookedFromDeveloperState;
  newbuildingChat: INewbuildingChat;
  newbuildingConsultant: INewbuildingConsultant;
  ebcInfo: IEbcState;
  /** Флаг для выкатки брэндирования */
  isBrandingAvailable: boolean;
  /** Перечень регионов ранжированного поиска */
  mlSearchRegions?: number[] | null;
  /** Флаг активности ML-выдачи для всех */
  isMlSearchForAllEnabled: boolean;
  /** Флаг для demand - является ли юзер собственником **/
  isHomeowner: boolean;
  soprApi: string;
  widgetsMarkup: IWidgetsMarkupState;
  infrastructureMap: TInfrastructureMapState;
  isCountrysidePromoEnabled: boolean;
  /** Списк publisherUserId объявлений, публикации которых учавствуют аукционах **/
  knAuctionCompanies: number[];
  auctionBanks: number[];
  dealRent: IDealRentState;
  renterForm: IRenterFormState;
  featureToggle: IFeatureToggleState;
  dealRentVasCost: number;
  isHomeownerDocsRequired: boolean;
  offersHistory: IOffersHistoryState;
  offerValuation: IOfferValuationState;
  /** Показываем кнопку аналитики  */
  showAnalyticsButton: boolean;
  entryToShowEnabled: boolean;
  favoritesAssignWidget: IFavoritesAssignWidgetState;
  banksList: IBankListState;
  mortgagePromoDesign: string | null;
  flatTourBooking: IFlatTourBookingState;
  crossedOutPriceExcludeNewbuildingIds: number[];
  comparisonNotification: IComparisonNotification | null;
  isQaMode: boolean;
  rs: IRSState;
  isRenterFormEnabled: boolean;
  infrastructureCategories: ECategory[];
  buyAnalyticsModal: IBuyAnalyticsModalState;
  bestPlaceModal: IBestPlaceReportModalState;
  recommendedVillages: IRecommendedVillages | null;
  genplan: IGenplanScheme | null;
  adfoxOffer: TAdfoxRequestedOffer | null;
  bookedOfferId: number;
  rosreestr: IRosreestrState | null;
  bestPlaceAnalytics: IBestPlaceAnalyticsState;
  buildersProjectsLocations: number[];
  suburbanBuildersProjects: ISuburbanProjectSuggest[];
  reviewsGoToAppModal: IReviewsGoToAppModalState;
  valuationTraps: IValuationTrapsState;
  dailyrentBookingCalendar: IDailyrentBookingCalendarState;
  dailyrentActionType: TDailyrentActionTypeState;
  isDeveloperCardRedesignEnabled: boolean;
  isPushToCallEnabled: boolean;
}

export type DispatchReduxAction = ThunkDispatch<IState, IApplicationContext, RootAction>;

export type IThunkActionCreator<T = void> = ThunkAction<T, IState, IApplicationContext, RootAction>;

let storeReminder: Store<IState>;

export function createStore(
  context: IApplicationContext,
  preloadedState: IState,
  isBrowser: boolean = false,
): Store<IState, RootAction> {
  const rootReducer = combineReducers<IState, RootAction>({
    abGroup: (state: IState['abGroup'] = 0) => state,
    abUseExperiments: abUseExperimentsReducer,
    aside: asideReducer,
    hideOffer: hideOfferReducer,
    bookedOfferId: (state: IState['bookedOfferId'] = 0) => state,
    captchaSiteKey: (state: IState['captchaSiteKey'] = '') => state,
    captchaSpamSiteKey: (state: IState['captchaSpamSiteKey'] = '') => state,
    commentsData: commentsReducer,
    favoriteMotivation: favoriteMotivationReducer,
    feedback: feedbackReducer,
    gallery: galleryReducer,
    legend: legendModalReducer,
    mapSimilar: mapSimilarReducer,
    mapTabs: mapTabsReducer,
    mortgage: mortgageStateReducer,
    mortgageGallery: mortgageGalleryStateReducer,
    newbuildingBookedFromDeveloper: newbuildingBookedFromDeveloperReducer,
    newbuildingChat: newbuildingChatReducer,
    newbuildingConsultant: (state: IState['newbuildingConsultant'] = {} as IState['newbuildingConsultant']) => state,
    offerChat: chatReducer,
    offerData: reduceReducers<IState['offerData']>(offerReducer, userDataReducer),
    promotionsVillageData: villagePromotionsReducer,
    offerStatsPopup: offerStatsPopupReducer,
    pageData: (state: IState['pageData'] = {} as IState['pageData']) => state,
    phoneCollapse: phoneCollapseReducer,
    phoneWarning: phoneWarningReducer,
    printModal: printModalReducer,
    pushSubscribed: pushSubscribedReducer,
    reclaimModal: reclaimModalReducer,
    remove: removeModalReducer,
    seoLinks: seoLinksReducer,
    serviceBaseUrl: (state: IState['serviceBaseUrl'] = '') => state,
    similarOffers: similarOffersReducer,
    specialPromoInfo: specialPromoInfoReducer,
    subscriptionPriceChangesPopup: subscriptionPriceChangesPopupReducer,
    tracking: trackingReducer,
    viewedSimilar: markViewedReducer,
    ebcInfo: ebcReducer,
    mlSearchRegions: (state: IState['mlSearchRegions'] = null) => state,
    isMlSearchForAllEnabled: (state: IState['isMlSearchForAllEnabled'] = false) => state,
    isBrandingAvailable: (state: IState['isBrandingAvailable'] = false) => state,
    isCountrysideOffersBuilderBadgeEnabled: (state: IState['isCountrysideOffersBuilderBadgeEnabled'] = false) => state,
    isHomeowner: homeownerReducer,
    soprApi: (state: IState['soprApi'] = '') => state,
    widgetsMarkup: widgetMarkupReducer,
    infrastructureMap: infrastructureMapReducer,
    infrastructureCategories: infrastructureCategoriesReducer,
    isCountrysidePromoEnabled: (state: IState['isCountrysidePromoEnabled'] = false) => state,
    knAuctionCompanies: knAuctionCompaniesReducer,
    auctionBanks: (state: IState['auctionBanks'] = Array.of<number>()) => state,
    dealRent,
    renterForm,
    complaintPopupOpen: complaintPopupOpenReducer,
    notAvailablePopupOpen: notAvailablePopupOpenReducer,
    featureToggle: (state: IState['featureToggle'] = {} as IState['featureToggle']) => state,
    dealRentVasCost: (state: IState['dealRentVasCost'] = 0) => state,
    isHomeownerDocsRequired: (state: IState['isHomeownerDocsRequired'] = false) => state,
    offersHistory: offersHistoryReducer,
    offerValuation: offerValuationReducer,
    showAnalyticsButton: (state: IState['showAnalyticsButton'] = false) => state,
    entryToShowEnabled: (state: IState['entryToShowEnabled'] = false) => state,
    isDeveloperCardRedesignEnabled: (state: IState['isDeveloperCardRedesignEnabled'] = false) => state,
    isPushToCallEnabled: (state: IState['isPushToCallEnabled'] = false) => state,
    favoritesAssignWidget: favoritesAssignWidgetReducer,
    banksList: banksListReducer,
    mortgagePromoDesign: (state = null) => state,
    flatTourBooking: flatTourBookingReducer,
    crossedOutPriceExcludeNewbuildingIds: (
      state: IState['crossedOutPriceExcludeNewbuildingIds'] = Array.of<number>(),
    ) => state,
    comparisonNotification: comparisonNotificationReducer,
    rs: rsReducer,
    isQaMode: (state: IState['isQaMode'] = false) => state,
    isRenterFormEnabled: (state: IState['isRenterFormEnabled'] = false) => state,
    buyAnalyticsModal: buyAnalyticsModalReducer,
    bestPlaceModal: bestPlaceModalReducer,
    recommendedVillages: recommendedVillagesReducer,
    genplan: genplanReducer,
    adfoxOffer: adfoxOfferReducer,
    isStartChatWidgetInDropdown: (state: IState['isStartChatWidgetInDropdown'] = false) => state,
    rosreestr: rosreestrReducer,
    bestPlaceAnalytics: bestPlaceAnalyticsReducer,
    buildersProjectsLocations: buildersProjectsLocationsReducer,
    suburbanBuildersProjects: suburbanBuildersProjectsReducer,
    reviewsGoToAppModal: reviewsGoToAppModalReducer,
    valuationTraps: valuationTrapsReducer,
    dailyrentBookingCalendar: dailyrentBookingCalendarReducer,
    dailyrentActionType: dailyrentActionTypeReducer,
  });

  const projectName = context.config.getStrict<string>('projectName');

  const createReduxStore = () =>
    configureStore({
      devTools: isBrowser ? { name: projectName } : false,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          thunk: {
            extraArgument: context,
          },
          /**
           * При локальной разработке (в т.ч. в тестах) у некоторых карточек все падает
           * из-за ошибки мутации стейта, сейчас это достаточно сложно поправить
           *
           * Временное решение: https://stackoverflow.com/questions/64695464/error-invariant-failed-a-state-mutation-was-detected-between-dispatches-in-th
           */
          ...(process.env.NODE_ENV === 'development' ? { immutableCheck: false, serializableCheck: false } : {}),
        }),
      preloadedState,
      reducer: rootReducer,
    });

  if (process.env.NODE_ENV === 'development') {
    if (isBrowser) {
      if (!storeReminder) {
        storeReminder = createReduxStore();

        return storeReminder;
      }

      storeReminder.replaceReducer(rootReducer);

      return storeReminder;
    }
  }

  return createReduxStore();
}

function reduceReducers<S>(...reducers: Reducer<S>[]) {
  return (prevState: S, action: RootAction) =>
    reducers.reduce((newState, reducer) => reducer(newState, action), prevState);
}
