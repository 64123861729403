/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetPaymentUrlModel,
  IGetPaymentUrlRequest,
  IMappers,
  IGetPaymentUrlResponse,
  IGetPaymentUrlResponseError,
  TGetPaymentUrlResponse,
} from './types';

const defaultConfig: TGetPaymentUrlModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'iap-reports-sale',
  pathApi: '/v1/get-payment-url/',
  hostType: 'ud-api',
} as TGetPaymentUrlModel;

function createGetPaymentUrlModel(parameters: IGetPaymentUrlRequest): TGetPaymentUrlModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetPaymentUrlOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetPaymentUrlRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetPaymentUrl<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetPaymentUrlOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetPaymentUrlResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetPaymentUrlModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetPaymentUrlResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetPaymentUrlResponseError);
    }
  }

  return { response, statusCode } as TGetPaymentUrlResponse;
}

export { defaultConfig, createGetPaymentUrlModel, fetchGetPaymentUrl };
