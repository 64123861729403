import { format, startOfToday } from 'date-fns';

import { setDailyrentActionType } from 'shared/actions/dailyrentActionType';
import {
  EDailyrentBookingCalendarActionTypes,
  TBookingAvailabilityData,
} from 'shared/actions/dailyrentBookingCalendar/types';
import { updateDailyrentUrlParameters } from 'shared/containers/BookingCalendarInputContainer/helpers';
import { ResponseError } from 'shared/errors/responseError';
import { ICheckPriceRequest } from 'shared/repositories/dailyrent/v1/check-price';
import {
  selectDailyrentBookingCalendarDates,
  selectDailyrentBookingCalendarGuestsCount,
} from 'shared/selectors/dailyrentBookingCalendar';
import { selectOfferId } from 'shared/selectors/offerData/offer/selectOfferId';
import { fetchCheckPrice } from 'shared/services/fetchCheckPrice';
import { DEFAULT_ERROR_MESSAGE, fetchGetOfferCalendar } from 'shared/services/fetchGetOfferCalendar';
import { IThunkActionCreator } from 'shared/store';
import { trackCalendarDatesSubmit } from 'shared/tracking/dailyrentBookingCalendar';
import { TDaysAvailability } from 'shared/types/bookingCalendar';
import { IOfferData } from 'shared/types/offerData';
import { actionGenerator } from 'shared/utils/redux/actionGenerator';

export const toggleCalendarModal = actionGenerator<
  EDailyrentBookingCalendarActionTypes.ToggleCalendarModal,
  boolean | void
>(EDailyrentBookingCalendarActionTypes.ToggleCalendarModal);

export const setDaysAvailability = actionGenerator<
  EDailyrentBookingCalendarActionTypes.SetDaysAvailability,
  TDaysAvailability
>(EDailyrentBookingCalendarActionTypes.SetDaysAvailability);

export const setInitialLoading = actionGenerator<EDailyrentBookingCalendarActionTypes.SetInitialLoading, boolean>(
  EDailyrentBookingCalendarActionTypes.SetInitialLoading,
);

export const setAvailabilityLoading = actionGenerator<
  EDailyrentBookingCalendarActionTypes.SetAvailabilityLoading,
  boolean
>(EDailyrentBookingCalendarActionTypes.SetAvailabilityLoading);

export const setError = actionGenerator<EDailyrentBookingCalendarActionTypes.SetError, string>(
  EDailyrentBookingCalendarActionTypes.SetError,
);

export const setDates = actionGenerator<
  EDailyrentBookingCalendarActionTypes.SetDates,
  { from: string | undefined; to: string | undefined }
>(EDailyrentBookingCalendarActionTypes.SetDates);

export const setBookingCalendarDataFromOffer = actionGenerator<
  EDailyrentBookingCalendarActionTypes.SetBookingCalendarDataFromOffer,
  IOfferData
>(EDailyrentBookingCalendarActionTypes.SetBookingCalendarDataFromOffer);

export const setBookingCalendarAvailabilityData = actionGenerator<
  EDailyrentBookingCalendarActionTypes.SetBookingCalendarAvailabilityData,
  TBookingAvailabilityData
>(EDailyrentBookingCalendarActionTypes.SetBookingCalendarAvailabilityData);

export const setCanBeBooked = actionGenerator<EDailyrentBookingCalendarActionTypes.SetDailyrentCanBeBooked, boolean>(
  EDailyrentBookingCalendarActionTypes.SetDailyrentCanBeBooked,
);

export const setGuestsCount = actionGenerator<EDailyrentBookingCalendarActionTypes.SetDailyrentGuestsCount, number>(
  EDailyrentBookingCalendarActionTypes.SetDailyrentGuestsCount,
);

/** Запрашивает календарь доступности */
export const loadCalendarDaysAvailability =
  (): IThunkActionCreator<Promise<void>> => async (dispatch, getState, context) => {
    const state = getState();
    const offerRealtyId = selectOfferId(state);
    const today = startOfToday();
    const startDate = format(today, 'yyyy-MM-dd');

    dispatch(setInitialLoading(true));

    try {
      const { calendar } = await fetchGetOfferCalendar(context, { offerRealtyId, startDate });
      dispatch(setDaysAvailability(calendar));
    } catch (err) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setInitialLoading(false));
    }
  };

/** Проверяет доступность */
export const checkAvailability =
  ({ dates }: Omit<ICheckPriceRequest, 'offerRealtyId'>): IThunkActionCreator<Promise<void | string>> =>
  async (dispatch, getState, context) => {
    const state = getState();
    const offerRealtyId = selectOfferId(state);
    dispatch(setAvailabilityLoading(true));

    try {
      const { actionType, ...availabilityTitles } = await fetchCheckPrice(context, {
        offerRealtyId,
        dates,
      });

      const bookingAvailabilityData: TBookingAvailabilityData = {
        dateInputText: availabilityTitles.dateInputText,
        defaultBookingMessage: availabilityTitles.onlineBookingMessage || '',
        price: availabilityTitles.mainPrice,
        paymentSummary: availabilityTitles.paymentSummary || null,
      };

      dispatch(setDailyrentActionType(actionType));
      dispatch(setBookingCalendarAvailabilityData(bookingAvailabilityData));
    } catch (error) {
      let errorMessage = DEFAULT_ERROR_MESSAGE;

      const isKnownError = error instanceof ResponseError;
      const errorCode =
        isKnownError && error.details && 'code' in error.details && typeof error.details.code === 'string'
          ? error.details.code
          : '';

      if (['minStay', 'isBooked'].includes(errorCode)) {
        errorMessage = error.message;
      }

      dispatch(setError(errorMessage));
      dispatch(setCanBeBooked(false));

      return errorMessage;
    } finally {
      dispatch(setAvailabilityLoading(false));
    }

    return;
  };

/** Изменение дат в календаре */
export const calendarDatesChange =
  ({ dates }: Omit<ICheckPriceRequest, 'offerRealtyId'>): IThunkActionCreator<Promise<void | string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const offerRealtyId = selectOfferId(state);
    const guestsCount = selectDailyrentBookingCalendarGuestsCount(state);

    const errorMessage = await dispatch(checkAvailability({ dates: { start: dates.start, end: dates.end } }));

    if (errorMessage) {
      return;
    }

    const newUrl = updateDailyrentUrlParameters({
      currentUrl: window.location.href,
      dates: {
        checkin: dates.start,
        checkout: dates.end,
      },
      guestsCount,
    });

    history.replaceState(undefined, '', newUrl);
    dispatch(toggleCalendarModal(false));
    dispatch(setDates({ from: dates.start, to: dates.end }));
    dispatch(setError(''));
    trackCalendarDatesSubmit(offerRealtyId);
  };

export const openBookingCalendar = (): IThunkActionCreator<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const dates = selectDailyrentBookingCalendarDates(state);
  const { from, to } = dates;

  dispatch(toggleCalendarModal(true));
  await dispatch(loadCalendarDaysAvailability());
  if (from && to) {
    dispatch(checkAvailability({ dates: { start: from, end: to } }));
  }
};
