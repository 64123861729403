import { IState } from '../store';
import { IAbUseExperiment } from '../types/abUseExperiments';

export function abUseExperimentsSelector(state: IState): IAbUseExperiment[] {
  return state.abUseExperiments;
}

export function getAbUseExperiment(
  experiments: IAbUseExperiment[],
  experimentName: string,
): IAbUseExperiment | undefined {
  return experiments.find(o => o.experimentName === experimentName);
}

export function isAbUseExperimentsActive(
  experiments: IAbUseExperiment[],
  experimentName: string,
  groupName: string,
): boolean {
  const experiment = getAbUseExperiment(experiments, experimentName);

  if (!experiment) {
    return false;
  }

  return experiment.groupName === groupName;
}

export const getIsAbUseExperimentsActiveFactory =
  (experimentName: string) =>
  (groupName: string) =>
  (experiments: IAbUseExperiment[]): boolean =>
    isAbUseExperimentsActive(experiments, experimentName, groupName);
