import { IAbUseExperiment } from '../../types/abUseExperiments';

export const CALLTRACKING_WARNING = 'aud_1266_calltracking_warning';

export enum ECalltrackingWarningType {
  CURRENT = 'current',
  WARNING = 'warning',
}

export const getCalltrackingWarningTypeExperiment = (experiments: IAbUseExperiment[]): ECalltrackingWarningType => {
  const experiment = experiments.find(exp => exp.experimentName === CALLTRACKING_WARNING);
  if (!experiment || !experiment.groupName) {
    return ECalltrackingWarningType.CURRENT;
  }

  return experiment.groupName as ECalltrackingWarningType;
};
