import { setAdminAccess } from 'shared/actions/offersHistory/actions';
import { fetchCheckAdminAccess } from 'shared/services/offersHistory/fetchCheckAdminAccess';
import { IThunkActionCreator } from 'shared/store';

export const getCheckAdminAccess = (): IThunkActionCreator<Promise<void>> => async (dispatch, getState, context) => {
  try {
    const res = (await fetchCheckAdminAccess(context)) as boolean;

    dispatch(setAdminAccess(res));
  } catch (e) {
    dispatch(setAdminAccess(false));
  }
};
