// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IOrderStatusSchema {
  /** Сообщение в банере **/
  description?: string;
  /** Статус записи **/
  type: EType;
}
export enum EType {
  'NoActive' = 'noActive',
  'SignedUp' = 'signedUp',
}
