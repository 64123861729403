/* eslint-disable max-lines */
import { TDealType, TCurrency, TPaymentPeriod } from '@cian/price-history-change-widget';
import { TPlacement, TInputType } from '@cian/ui-kit';

import {
  TUserTrustLevel,
  TAccountType,
  TUserType,
  TOfferType,
  TTenantsType,
  TIncludedOption,
  TVatType,
  TLeaseType,
  TAreaUnitType,
  TLandStatus,
  TRepair,
  TDecorationType,
  TWсType,
  TWcLocation,
  TParkingLocationType,
  TPurposeType,
  TParkingType,
  TLiftType,
  TCranageType,
  TFlatType,
  TOfferStatus,
  TLayout,
  TBSCInputType,
  TFloorMaterial,
  TFurniturePresence,
  TAccessType,
  TConditionType,
  TGatesType,
  TGarageMaterial,
  TGarageStatus,
  TPermittedUseType,
  TGarageCategory,
  TPropertyType,
  TGarageType,
  TBuildingClassType,
  TVentilationType,
  TConditioningType,
  THeatingType,
  TExtinguishingSystemType,
  TLocationType,
  TDrainageType,
  TWaterType,
  TSuburbanWaterType,
  TDrivewayType,
  TGasType,
  TNewObjectRoomType,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { TAddressType } from 'shared/types/geo/address';

export const AddressType = {
  Country: 'country' as TAddressType,
  District: 'district' as TAddressType,
  House: 'house' as TAddressType,
  Location: 'location' as TAddressType,
  Okrug: 'okrug' as TAddressType,
  Raion: 'raion' as TAddressType,
  Road: 'road' as TAddressType,
  Street: 'street' as TAddressType,
  Underground: 'underground' as TAddressType,
};

export const UserTrustLevel = {
  Danger: 'danger' as TUserTrustLevel,
  Excluded: 'excluded' as TUserTrustLevel,
  Involved: 'involved' as TUserTrustLevel,
  New: 'new' as TUserTrustLevel,
  NotInvolved: 'notInvolved' as TUserTrustLevel,
};

export const AccountType = {
  Agency: 'agency' as TAccountType,
  ManagementCompany: 'managementCompany' as TAccountType,
  RentDepartment: 'rentDepartment' as TAccountType,
  Specialist: 'specialist' as TAccountType,
};

export const UserType = {
  Agency: 'agency' as TUserType,
  Developer: 'developer' as TUserType,
  DeveloperCompany: 'developerCompany' as TUserType,
  OfficialRepresentativeAgency: 'officialRepresentativeAgency' as TUserType,
  OfficialRepresentativeCompany: 'officialRepresentativeCompany' as TUserType,
  Other: 'other' as TUserType,
  Physical: 'physical' as TUserType,
  RealtorBase: 'realtorBased' as TUserType,
  RealtorNonCommercial: 'realtorNotCommerce' as TUserType,
  RealtorPro: 'realtorProf' as TUserType,
};

export const DealType = {
  Rent: 'rent' as TDealType,
  Sale: 'sale' as TDealType,
};

export const OfferType = {
  Commercial: 'commercial' as TOfferType,
  Flat: 'flat' as TOfferType,
  Suburban: 'suburban' as TOfferType,
};

export const TenantsType = {
  Any: 'any' as TTenantsType,
  Family: 'family' as TTenantsType,
  Female: 'female' as TTenantsType,
  Male: 'male' as TTenantsType,
};

export const Currency = {
  Eur: 'eur' as TCurrency,
  Rur: 'rur' as TCurrency,
  Usd: 'usd' as TCurrency,
};

export const IncludedOption = {
  OperationalCosts: 'operationalCosts' as TIncludedOption,
  UtilityCharges: 'utilityCharges' as TIncludedOption,
};

export const VatType = {
  Included: 'included' as TVatType,
  NotIncluded: 'notIncluded' as TVatType,
  Usn: 'usn' as TVatType,
};

export const PaymentPeriod = {
  Annual: 'annual' as TPaymentPeriod,
  Monthly: 'monthly' as TPaymentPeriod,
};

export const LeaseType = {
  Direct: 'direct' as TLeaseType,
  JointVenture: 'jointVenture' as TLeaseType,
  Sublease: 'sublease' as TLeaseType,
};

export const AreaUnit = {
  Hectare: 'hectare' as TAreaUnitType,
  Sotka: 'sotka' as TAreaUnitType,
};

export const LandStatus = {
  Farm: 'farm' as TLandStatus,
  ForAgriculturalPurposes: 'forAgriculturalPurposes' as TLandStatus,
  ForestArea: 'forestArea' as TLandStatus,
  Gardening: 'gardening' as TLandStatus,
  GardeningNonProfitPartnership: 'gardeningNonProfitPartnership' as TLandStatus,
  IndividualHousingConstruction: 'individualHousingConstruction' as TLandStatus,
  IndustrialLand: 'industrialLand' as TLandStatus,
  IndustryTransportCommunications: 'industryTransportCommunications' as TLandStatus,
  InvestmentProject: 'investmentProject' as TLandStatus,
  OfProtectedCategories: 'ofProtectedCategories' as TLandStatus,
  PrivateFarm: 'privateFarm' as TLandStatus,
  Reserve: 'reserve' as TLandStatus,
  Settlements: 'settlements' as TLandStatus,
  SuburbanNonProfitPartnership: 'suburbanNonProfitPartnership' as TLandStatus,
  SuburbanNonProfitSettlementsPartnership: 'suburbanNonProfitSettlementsPartnership' as TLandStatus,
  WaterArea: 'waterArea' as TLandStatus,
};

export const RepairType = {
  Cosmetic: 'cosmetic' as TRepair,
  Design: 'design' as TRepair,
  Euro: 'euro' as TRepair,
  No: 'no' as TRepair,
};

export const DecorationType = {
  Fine: 'rough' as TDecorationType,
  Rough: 'rought' as TDecorationType,
  Without: 'without' as TDecorationType,
};

export const WcType = {
  Combined: 'combined' as TWсType,
  Separate: 'separate' as TWсType,
};

export const WcLocationType = {
  Indoors: 'indoors' as TWcLocation,
  Outdoors: 'outdoors' as TWcLocation,
};

export const ParkingLocationType = {
  External: 'external' as TParkingLocationType,
  Internal: 'internal' as TParkingLocationType,
};

export const PurposeType = {
  Cargo: 'cargo' as TPurposeType,
  Passenger: 'passenger' as TPurposeType,
};

export const ParkingType = {
  Ground: 'ground' as TParkingType,
  Multilevel: 'multilevel' as TParkingType,
  Open: 'open' as TParkingType,
  Roof: 'roof' as TParkingType,
  Spontaneous: 'spontaneous' as TParkingType,
  Underground: 'underground' as TParkingType,
};

export const LiftType = {
  Cargo: 'cargo' as TLiftType,
  Escalator: 'escalator' as TLiftType,
  Lift: 'lift' as TLiftType,
  Passenger: 'passenger' as TLiftType,
  Telpher: 'telpher' as TLiftType,
  Travelator: 'travelator' as TLiftType,
};

export const CranageType = {
  Beam: 'beam' as TCranageType,
  Gantry: 'gantry' as TCranageType,
  Overhead: 'overhead' as TCranageType,
  Railway: 'railway' as TCranageType,
};

export const FlatType = {
  OpenPlan: 'openPlan' as TFlatType,
  Rooms: 'rooms' as TFlatType,
  Studio: 'studio' as TFlatType,
};

export const OfferStatus = {
  Blocked: 'blocked' as TOfferStatus,
  Deactivated: 'deactivated' as TOfferStatus,
  Deleted: 'deleted' as TOfferStatus,
  Draft: 'draft' as TOfferStatus,
  Moderate: 'moderate' as TOfferStatus,
  Published: 'published' as TOfferStatus,
  Refused: 'refused' as TOfferStatus,
  RemovedByModerator: 'removedByModerator' as TOfferStatus,
  Sold: 'sold' as TOfferStatus,
};

export const PlacementType = {
  ShoppingMall: 'shoppingMall' as TPlacement,
  StreetRetail: 'streetRetail' as TPlacement,
};

export const Layout = {
  Cabinet: 'cabinet' as TLayout,
  CorridorPlan: 'corridorplan' as TLayout,
  Mixed: 'mixed' as TLayout,
  OpenSpace: 'openSpace' as TLayout,
};

export const BSCInputType = {
  CommonFromTheStreet: 'commonFromTheStreet' as TBSCInputType,
  CommonFromTheYard: 'commonFromTheYard' as TBSCInputType,
  SeparateFromTheStreet: 'separateFromTheStreet' as TBSCInputType,
  SeparateFromTheYard: 'separateFromTheYard' as TBSCInputType,
};

export const InputType = {
  CommonFromStreet: 'commonFromStreet' as TInputType,
  CommonFromYard: 'commonFromYard' as TInputType,
  SeparateFromStreet: 'separateFromStreet' as TInputType,
  SeparateFromYard: 'separateFromYard' as TInputType,
};

export const FloorMaterial = {
  Asphalt: 'asphalt' as TFloorMaterial,
  Concrete: 'concrete' as TFloorMaterial,
  Laminate: 'laminate' as TFloorMaterial,
  Linoleum: 'linoleum' as TFloorMaterial,
  Polymeric: 'polymeric' as TFloorMaterial,
  ReinforcedConcrete: 'reinforcedConcrete' as TFloorMaterial,
  SelfLeveling: 'selfLeveling' as TFloorMaterial,
  Tile: 'tile' as TFloorMaterial,
  Wood: 'wood' as TFloorMaterial,
};

export const FurniturePresence = {
  No: 'no' as TFurniturePresence,
  Optional: 'optional' as TFurniturePresence,
  Yes: 'yes' as TFurniturePresence,
};

export const AccessType = {
  Free: 'free' as TAccessType,
  PassSystem: 'passSystem' as TAccessType,
};

export const ConditionType = {
  CosmeticRepairsRequired: 'cosmeticRepairsRequired' as TConditionType,
  Design: 'design' as TConditionType,
  Finishing: 'finishing' as TConditionType,
  MajorRepairsRequired: 'majorRepairsRequired' as TConditionType,
  Office: 'office' as TConditionType,
  Typical: 'typical' as TConditionType,
};

export const GatesType = {
  AtZero: 'atZero' as TGatesType,
  DockType: 'dockType' as TGatesType,
  OnRamp: 'onRamp' as TGatesType,
};

export const GarageMaterial = {
  Brick: 'brick' as TGarageMaterial,
  Metal: 'metal' as TGarageMaterial,
};

export const GarageStatus = {
  ByProxy: 'byProxy' as TGarageStatus,
  Cooperative: 'cooperative' as TGarageStatus,
  Ownership: 'ownership' as TGarageStatus,
};

export const PermittedUseType = {
  Agricultural: 'agricultural' as TPermittedUseType,
  BusinessManagement: 'businessManagement' as TPermittedUseType,
  CommonUseArea: 'commonUseArea' as TPermittedUseType,
  HighriseBuildings: 'highriseBuildings' as TPermittedUseType,
  HotelAmenities: 'hotelAmenities' as TPermittedUseType,
  IndividualHousingConstruction: 'individualHousingConstruction' as TPermittedUseType,
  Industry: 'industry' as TPermittedUseType,
  Leisure: 'leisure' as TPermittedUseType,
  LowriseHousing: 'lowriseHousing' as TPermittedUseType,
  PublicUseOfCapitalConstruction: 'publicUseOfCapitalConstruction' as TPermittedUseType,
  ServiceVehicles: 'serviceVehicles' as TPermittedUseType,
  ShoppingCenters: 'shoppingCenters' as TPermittedUseType,
  Warehouses: 'warehouses' as TPermittedUseType,
};

export const GarageCategory = {
  BuiltIn: 'builtIn' as TGarageCategory,
  Capital: 'capital' as TGarageCategory,
  Samostroy: 'samostroy' as TGarageCategory,
  Shell: 'shell' as TGarageCategory,
};

export const PropertyType = {
  Building: 'building' as TPropertyType,
  FreeAppointment: 'freeAppointment' as TPropertyType,
  Garage: 'garage' as TPropertyType,
  Industry: 'industry' as TPropertyType,
  Land: 'land' as TPropertyType,
  Office: 'office' as TPropertyType,
  ShoppingArea: 'shoppingArea' as TPropertyType,
  Warehouse: 'warehouse' as TPropertyType,
};

export const GarageType = {
  Box: 'box' as TGarageType,
  Garage: 'garage' as TGarageType,
  ParkingPlace: 'parkingPlace' as TGarageType,
};

export const BuildingClassType = {
  A: 'a' as TBuildingClassType,
  APlus: 'aPlus' as TBuildingClassType,
  B: 'b' as TBuildingClassType,
  BMinus: 'bMinus' as TBuildingClassType,
  BPlus: 'bPlus' as TBuildingClassType,
  C: 'c' as TBuildingClassType,
  CPlus: 'cPlus' as TBuildingClassType,
  D: 'd' as TBuildingClassType,
};

export const VentilationType = {
  Forced: 'forced' as TVentilationType,
  Natural: 'natural' as TVentilationType,
  No: 'no' as TVentilationType,
};

export const ConditioningType = {
  Central: 'central' as TConditioningType,
  Local: 'local' as TConditioningType,
  No: 'no' as TConditioningType,
};

export const HeatingType = {
  Autonomous: 'autonomous' as THeatingType,
  AutonomousGas: 'autonomous_gas' as THeatingType,
  Boiler: 'boiler' as THeatingType,
  Central: 'central' as THeatingType,
  CentralCoal: 'central_coal' as THeatingType,
  CentralGas: 'central_gas' as THeatingType,
  Diesel: 'diesel' as THeatingType,
  Electric: 'electric' as THeatingType,
  Fireplace: 'fireplace' as THeatingType,
  No: 'no' as THeatingType,
  Other: 'other' as THeatingType,
  SolidFuelBoiler: 'solid_fuel_boiler' as THeatingType,
  Stove: 'stove' as THeatingType,
};

export const ExtinguishingSystemType = {
  Alarm: 'alarm' as TExtinguishingSystemType,
  Gas: 'gas' as TExtinguishingSystemType,
  Hydrant: 'hydrant' as TExtinguishingSystemType,
  No: 'no' as TExtinguishingSystemType,
  Powder: 'powder' as TExtinguishingSystemType,
  Sprinkler: 'sprinkler' as TExtinguishingSystemType,
};

export const LocationType = {
  Border: 'border' as TLocationType,
  Location: 'location' as TLocationType,
  No: 'no' as TLocationType,
};

export const DrainageType = {
  Autonomous: 'autonomous' as TDrainageType,
  Central: 'central' as TDrainageType,
  SepticTank: 'septic_tank' as TDrainageType,
  Storm: 'storm' as TDrainageType,
  TreatmentFacilities: 'treatment_facilities' as TDrainageType,
  Cesspool: 'cesspool' as TDrainageType,
};

export const WaterType = {
  Autonomous: 'autonomous' as TWaterType,
  Central: 'central' as TWaterType,
  PumpingStation: 'pumping_station' as TWaterType,
  WaterIntakeFacility: 'water_intake_facility' as TWaterType,
  WaterTower: 'water_tower' as TWaterType,
};

export const SuburbanWaterType = {
  Central: 'central' as TSuburbanWaterType,
  Borehole: 'borehole' as TSuburbanWaterType,
  Well: 'well' as TSuburbanWaterType,
};

export const DrivewayType = {
  Asphalt: 'asphalt' as TDrivewayType,
  Ground: 'ground' as TDrivewayType,
  No: 'no' as TDrivewayType,
};

export const GasType = {
  Border: 'border' as TGasType,
  GasHolder: 'gas_holder' as TGasType,
  Main: 'main' as TGasType,
};

export const NewObjectRoomType = {
  Bed: 'bed' as TNewObjectRoomType,
  FiveRoom: 'fiveRoom' as TNewObjectRoomType,
  FourRoom: 'fourRoom' as TNewObjectRoomType,
  Large: 'large' as TNewObjectRoomType,
  OneRoom: 'oneRoom' as TNewObjectRoomType,
  OpenPlan: 'openPlan' as TNewObjectRoomType,
  Room: 'room' as TNewObjectRoomType,
  Share: 'share' as TNewObjectRoomType,
  Studio: 'studio' as TNewObjectRoomType,
  ThreeRoom: 'threeRoom' as TNewObjectRoomType,
  TwoRoom: 'twoRoom' as TNewObjectRoomType,
};
