import { Button, IconClose24, ModalWindow, UIHeading2, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface ICallbackFallbackModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose(): void;
  onRetry(): void;
}

/**
 * Модалка отображается, если не удалось показать виджет колбэка.
 */
export const CallbackFallbackModal: React.FC<ICallbackFallbackModalProps> = ({
  isLoading,
  isOpen,
  onClose,
  onRetry,
}) => {
  return (
    <ModalWindow
      content={
        <div className={styles['content']} data-testid="CallbackFallbackModalContent">
          <div className={styles['icon-wrapper']}>
            <IconClose24 color="decorative__theme__red" />
          </div>
          <div className={styles['heading']}>
            <UIHeading2>Что-то пошло не так...</UIHeading2>
          </div>
          <UIText1>Попробуйте ещё раз</UIText1>
        </div>
      }
      fixed={false}
      footer={
        <div className={styles['footer']}>
          <Button fullWidth={false} onClick={onRetry} size="XS" loading={isLoading}>
            Попробовать ещё раз
          </Button>
        </div>
      }
      onClose={onClose}
      open={isOpen}
      width={648}
    />
  );
};
