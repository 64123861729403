/* eslint-disable max-lines, @typescript-eslint/no-non-null-assertion */
import { addSavedOffer } from '@cian/favorites-assign-widget';

import { setDailyrentActionTypeFromOffer } from 'shared/actions/dailyrentActionType';
import { setBookingCalendarDataFromOffer } from 'shared/actions/dailyrentBookingCalendar/actions';
import {
  IAgentAvailabilitySchema,
  IAgentSchema,
  IUserSchema,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { prepareOfferData } from './mappers';
import { TOfferData } from './types';
import {
  EType as EOrderStatusType,
  IOrderStatusSchema,
} from '../../../node/repositories/offer-card/entities/newbuilding_flat_view_order/OrderStatusSchema';
import { EDealType, EOfferType } from '../../../node/repositories/offer-card/v1/get-offer-data';
import { IOrderStatusSchema as DEPRECATED_IOrderStatusSchema } from '../../../shared/repositories/offer-card/entities/newbuilding_flat_view_order/OrderStatusSchema';
import {
  AddToComparisonActionType,
  DeleteFromComparisonActionType,
  TOfferComparisonActions,
} from '../../actions/comparison';
import { setIsTooltipOpen } from '../../actions/flatTourBooking';
import { getAgentDataForCard } from '../../api/agent_data';
import { changeFavorite } from '../../api/favorite';
import { getOfferData } from '../../api/offer';
import { fetchCheckUserSubscription } from '../../repositories/price-change-notification/v1/check-user-subscription';
import { getFavoritesFolderEnabled } from '../../selectors/featureToggle';
import { IOfferData } from '../../types/offerData';
import { EStatus } from '../../types/offersComparisonStatus';
import { showFavoriteMotivation } from '../favoriteMotivation';
import { IThunkActionCreator } from '../index';

export * from './constants';

export const changeOfferFoldersCount = (offerFoldersCount: number): IChangeOfferFoldersCount => ({
  type: 'IChangeOfferFoldersCount',
  offerFoldersCount,
});

export function fetchOffer(
  dealType: 'sale' | 'rent',
  offerType: 'flat' | 'suburban' | 'commercial',
  offerId: number,
  realtyUserId: number | undefined,
  anonymousUserId: string | undefined,
  dates?: { checkIn: string; checkOut: string },
  guestsCount?: number,
): IThunkActionCreator<Promise<void>> {
  return (dispatch, getState, context) => {
    dispatch({ offerId, type: 'IOfferSetId' });

    const { config } = context;
    const offerCardTimeout = config.get<number>('offerCard.timeout') || undefined;
    const {
      pageData: { subdomain },
    } = getState();
    const { checkIn, checkOut } = dates || {};

    return getOfferData(
      context.httpApi,
      {
        anonymousUserId,
        dealType: dealType as EDealType,
        offerId,
        offerType: offerType as EOfferType,
        realtyUserId,
        subdomain,
        checkIn,
        checkOut,
        minBeds: guestsCount,
      },
      offerCardTimeout,
    ).then(offerDataRaw => {
      const offerData = prepareOfferData(offerDataRaw as unknown as TOfferData);
      const note = offerData?.notes?.offer?.text || '';

      dispatch<IOfferFetched>({ offerData, type: 'IOfferFetched' });
      dispatch({ payload: { commentText: note, isOpen: note.length > 1 }, type: 'ICommentsInit' });
      dispatch(setBookingCalendarDataFromOffer(offerData));
      dispatch(setDailyrentActionTypeFromOffer(offerData));
      dispatch(setIsTooltipOpen(offerData.newbuildingFlatViewOrder?.orderStatus?.type === EOrderStatusType.SignedUp));
    });
  };
}

export function fetchAgent(): IThunkActionCreator {
  return (dispatch, getState, context) => {
    const state = getState();
    const realtyUserId = state.offerData.offer.publishedUserId;

    if (!realtyUserId) {
      dispatch({
        type: 'IAgentFetched',
      });

      return;
    }

    const subdomain = state.pageData.subdomain;

    return getAgentDataForCard(context.httpApi, {
      realtyUserId,
      subdomain,
    }).then(response => {
      let agentData: IAgentSchema | undefined;

      if (response && response.data && response.data.agent) {
        agentData = response.data.agent;
      }

      dispatch({
        agentData,
        type: 'IAgentFetched',
      });
    });
  };
}

export function changeFavoriteAction(isFavoriteForce?: boolean): IThunkActionCreator {
  return (dispatch, getState, context) => {
    const state = getState();

    const isFavoritesFolderEnabled = getFavoritesFolderEnabled(state);
    const isFavorite = isFavoriteForce === undefined ? !state.offerData.isFavorite : isFavoriteForce;

    const currentOfferFoldersCount = state.offerData.offerFoldersCount;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentCount = (window as any).__header_favorites_count__ || 0;

    if (
      isFavorite &&
      (!state.offerData.user || !state.offerData.user.isAuthenticated) &&
      currentCount >= 5 &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.waitForLoginPopup
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.waitForLoginPopup('favorites');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (window.__headerLoginPopupShowMotivation) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.__headerLoginPopupShowMotivation();
      }
    } else {
      const apiBaseUrl = context.config.get('apiBaseUrl') as string;
      const {
        pageData: { subdomain },
        offerData: {
          offer: { cianId, dealType, offerType },
        },
      } = state;

      if (subdomain && apiBaseUrl) {
        if (
          isFavorite &&
          (!state.offerData.user || !state.offerData.user.isAuthenticated) &&
          state.favoriteMotivation.isActive
        ) {
          dispatch(showFavoriteMotivation());
        }

        changeFavorite(
          context.custom.makeRequest,
          apiBaseUrl,
          subdomain,
          cianId,
          dealType,
          offerType,
          isFavorite,
          isFavoritesFolderEnabled,
        )
          .then(res => {
            dispatch<IChangeFavorite>({
              isFavorite,
              type: 'IChangeFavorite',
            });

            dispatch(fetchSubscriptionsStatus(state.offerData.offer.id));

            if (window.__header_updateFavorites__) {
              window.__header_updateFavorites__(isFavorite);

              // если был добавлен ЖК
              if (res.newbuilding_id !== undefined) {
                window.__header_updateFavorites__(true);
              }
            }

            if (!isFavoritesFolderEnabled || isFavoriteForce !== undefined) {
              return;
            }

            const userHasFolders = Boolean(res.folderInfo && res.folderInfo.hasFolders);

            if (isFavorite) {
              const folder = res.folderInfo?.folder;

              let savedInFolders = null;
              if (folder && folder.name && folder.id) {
                savedInFolders = [
                  {
                    id: folder.id,
                    name: folder.name,
                  },
                ];
                dispatch(changeOfferFoldersCount(1));
              }

              dispatch(
                addSavedOffer({
                  offerIds: [cianId],
                  savedInFolders,
                  userHasFolders,
                }),
              );
            } else {
              dispatch(changeOfferFoldersCount(0));
              if (currentOfferFoldersCount && currentOfferFoldersCount > 0) {
                dispatch(setRemovedFromFavorites(true));
              }
              dispatch(
                addSavedOffer({
                  offerIds: [cianId],
                  savedInFolders: [],
                  userHasFolders,
                }),
              );
            }
          })
          .catch(e => {
            throw e;
          });
      }
    }
  };
}

export function fetchSubscriptionsStatus(offerId: number): IThunkActionCreator {
  // tslint:disable-next-line:variable-name
  return (dispatch, _getState, { httpApi }) => {
    return fetchCheckUserSubscription({ httpApi, parameters: { offerId } }).then(({ response, statusCode }) => {
      if (statusCode === 200) {
        dispatch<ISubscriptionFetched>({
          type: 'ISubscriptionFetched',
          isSubscribed: response.subscription.isSubscribed,
          email: response.subscription.email,
        });
      } else {
        throw new Error(response.message);
      }
    });
  };
}

export function subscribeSuccess(email: string): ISubscribeSuccess {
  return {
    payload: {
      email,
    },
    type: 'ISubscribeSuccess',
  };
}

export function unsubscribeSuccess(): IUnsubscribeSuccess {
  return {
    type: 'IUnsubscribeSuccess',
  };
}

export function saveAuthorizedUser(user: IUserSchema): IThunkActionCreator {
  return dispatch => {
    dispatch({
      payload: {
        user,
      },
      type: 'IUserAuthorizeSuccess',
    });
  };
}

export function setOfferHidden(isHiddenByUser: boolean): ISetHidden {
  return {
    type: 'ISetHidden',
    isHiddenByUser,
  };
}

export function setRemovedFromFavorites(value: boolean): ISetRemovedFromFavorites {
  return {
    type: 'ISetRemovedFromFavorites',
    payload: {
      value,
    },
  };
}

export interface IChangeFavorite {
  type: 'IChangeFavorite';
  isFavorite: boolean;
}

export interface IChangeOfferFoldersCount {
  type: 'IChangeOfferFoldersCount';
  offerFoldersCount: number;
}

export interface IOfferFetched {
  type: 'IOfferFetched';
  offerData: TOfferData;
}

export interface IAgentFetched {
  type: 'IAgentFetched';
  agentData?: IAgentSchema;
}

export interface IOfferSetId {
  type: 'IOfferSetId';
  offerId: number;
}

export interface ISubscribeSuccess {
  type: 'ISubscribeSuccess';
  payload: {
    email: string;
  };
}

export interface IUnsubscribeSuccess {
  type: 'IUnsubscribeSuccess';
}

export interface IUserAuthorize {
  type: 'IUserAuthorizeSuccess';
  payload: {
    user: IUserSchema;
  };
}

export interface IWorkTimeStatusFetched {
  type: 'IWorkTimeStatusFetched';
  payload: {
    activated: boolean;
  };
}

export interface IWorkTimeAvailabilityFetched {
  type: 'IWorkTimeAvailabilityFetched';
  payload: IAgentAvailabilitySchema;
}

export interface ISubscriptionFetched {
  type: 'ISubscriptionFetched';
  email: string | null;
  isSubscribed: boolean;
}

export interface ISetHidden {
  type: 'ISetHidden';
  isHiddenByUser: boolean;
}

export interface IFlatViewOrderUpdated {
  type: 'IFlatViewOrderUpdated';
  payload: IOrderStatusSchema;
}

export interface ISetRemovedFromFavorites {
  type: 'ISetRemovedFromFavorites';
  payload: {
    value: boolean;
  };
}

export type Action =
  | IAgentFetched
  | IChangeFavorite
  | IOfferFetched
  | IOfferSetId
  | ISubscribeSuccess
  | IUnsubscribeSuccess
  | IUserAuthorize
  | IWorkTimeStatusFetched
  | IWorkTimeAvailabilityFetched
  | ISetHidden
  | ISubscriptionFetched
  | IFlatViewOrderUpdated
  | IChangeOfferFoldersCount
  | ISetRemovedFromFavorites
  | TOfferComparisonActions;

export function offerReducer(state: IOfferData, action: Action): IOfferData {
  switch (action.type) {
    case 'IAgentFetched':
      if (!action.agentData) {
        return state;
      }

      return {
        ...state,
        agent: action.agentData,
      };

    case 'IOfferSetId':
      return {
        ...state,
        offer: {
          ...state.offer,
          id: action.offerId,
        },
      };
    case 'IChangeFavorite':
      return {
        ...state,
        isFavorite: action.isFavorite,
      };
    case 'IChangeOfferFoldersCount':
      return {
        ...state,
        offerFoldersCount: action.offerFoldersCount,
      };
    case 'IOfferFetched':
      return {
        ...state,
        ...action.offerData,
      } as IOfferData;
    case 'ISetHidden':
      return { ...state, isHiddenByUser: action.isHiddenByUser };
    case 'ISubscribeSuccess':
      return {
        ...state,
        subscription: {
          ...state.subscription,
          email: action.payload.email,
          isSubscribed: true,
        },
      };
    case 'IUserAuthorizeSuccess':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'IUnsubscribeSuccess':
      return {
        ...state,
        subscription: {
          ...state.subscription,

          email: state.subscription!.email,
          isSubscribed: false,
        },
      };
    case 'ISubscriptionFetched':
      return {
        ...state,
        subscription: {
          ...state.subscription,
          email: action.email,
          isSubscribed: action.isSubscribed,
        },
      };

    case 'IFlatViewOrderUpdated':
      return {
        ...state,
        newbuildingFlatViewOrder: {
          isEnabled: state.newbuildingFlatViewOrder?.isEnabled || true,
          orderStatus: action.payload as DEPRECATED_IOrderStatusSchema,
        },
      };
    case 'ISetRemovedFromFavorites':
      return {
        ...state,
        removedFromFavorites: action.payload.value,
      };

    case AddToComparisonActionType:
      return {
        ...state,
        offersComparisonStatus: {
          status: EStatus.Added,
          description: 'Добавлено в сравнение',
        },
      };
    case DeleteFromComparisonActionType:
      return {
        ...state,
        offersComparisonStatus: {
          status: EStatus.Available,
          description: 'Добавить к сравнению',
        },
      };

    default:
      return state || {};
  }
}
