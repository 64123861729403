export const AddToComparisonActionType = 'offersComparison/ADD_TO_COMPARISON';

export const DeleteFromComparisonActionType = 'offersComparison/DELETE_FROM_COMPARISON';

export interface IAddToComparisonAction {
  type: 'offersComparison/ADD_TO_COMPARISON';
  offerId: number;
}

export interface IDeleteFromComparisonAction {
  type: 'offersComparison/DELETE_FROM_COMPARISON';
  offerId: number;
}

export type TOfferComparisonActions = IAddToComparisonAction | IDeleteFromComparisonAction;
