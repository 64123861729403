export type TPushSubscribed = boolean;

export interface IPushSubscribedSetValueAction {
  type: 'IPushSubscribedSetValueAction';
  value: boolean;
}

export function setKeyPushNotificationValue(value: boolean): IPushSubscribedSetValueAction {
  return {
    type: 'IPushSubscribedSetValueAction',
    value,
  };
}

const initialState = false;

export function pushSubscribedReducer(
  state: TPushSubscribed = initialState,
  action: IPushSubscribedSetValueAction,
): TPushSubscribed {
  switch (action.type) {
    case 'IPushSubscribedSetValueAction':
      return action.value;

    default:
      return state;
  }
}
