import { Spinner, UIHeading4 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './StatusBar.css';

interface IStatusBarProps {
  statusMessage: string | null;
  hasSpinner: boolean;
  hasError: boolean;
}

export const StatusBar: React.FC<IStatusBarProps> = ({ statusMessage, hasSpinner, hasError }) => {
  return (
    <div className={cx(styles['container'], { [styles['error']]: hasError })}>
      {hasSpinner && (
        <div data-testid="Spinner" className={styles['spinner-wrapper']}>
          <Spinner size={16} color="white_100" />
        </div>
      )}
      <UIHeading4 color="white_100">{statusMessage}</UIHeading4>
    </div>
  );
};

StatusBar.displayName = 'StatusBar';
