import { ConfigurationError } from '@cian/peperrors/shared';
import * as React from 'react';

interface IMakeContextModuleOptions<T> {
  contextName: string;
  defaultValue?: T;
}

export function makeContextModule<T>(options: IMakeContextModuleOptions<T>) {
  const { defaultValue = null, contextName } = options;

  const Context = React.createContext<T>(defaultValue as T);
  Context.displayName = contextName;

  const useContext = () => {
    const context = React.useContext(Context);

    if (!context) {
      throw new ConfigurationError({
        message: `use${contextName} used outside of it's context`,
        domain: contextName,
      });
    }

    return context;
  };

  return {
    Context,
    useContext,
  };
}
