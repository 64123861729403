import { apiAddFeedback } from './api';
import { IThunkActionCreator } from '../../store';

export enum EAddFeedbackStatus {
  Initial = 'Initial',
  Loading = 'Loading',
  Succeed = 'Succeed',
  Failed = 'Failed',
}

export enum EFeedbackActionTypes {
  openFeedbackPopup = 'feedback/IFeedbackPopupOpened',
  closeFeedbackPopup = 'feedback/IFeedbackPopupClosed',
  openFeedbackFormPopup = 'feedback/IFeedbackFormPopupOpened',
  closeFeedbackFormPopup = 'feedback/IFeedbackFormPopupClosed',
  openFeedbackComplaintPopup = 'feedback/IFeedbackComplaintPopupOpened',
  closeFeedbackComplaintPopup = 'feedback/IFeedbackComplaintPopupClosed',
  openFeedbackSentPopup = 'feedback/IFeedbackSentPopupOpened',
  closeFeedbackSentPopup = 'feedback/IFeedbackSentPopupClosed',
  setFeedbackSent = 'feedback/ISetFeedbackSent',
  addFeedbackStatusChanged = 'feedback/IAddFeedbackStatusChanged',
}

export interface IFeedbackPopupOpened {
  type: EFeedbackActionTypes.openFeedbackPopup;
}
export interface IFeedbackPopupClosed {
  type: EFeedbackActionTypes.closeFeedbackPopup;
}
export interface IFeedbackFormPopupOpened {
  type: EFeedbackActionTypes.openFeedbackFormPopup;
}
export interface IFeedbackFormPopupClosed {
  type: EFeedbackActionTypes.closeFeedbackFormPopup;
}
export interface IFeedbackComplaintPopupOpened {
  type: EFeedbackActionTypes.openFeedbackComplaintPopup;
}
export interface IFeedbackComplaintPopupClosed {
  type: EFeedbackActionTypes.closeFeedbackComplaintPopup;
}
export interface IAddFeedbackStatusChanged {
  type: EFeedbackActionTypes.addFeedbackStatusChanged;
  status: EAddFeedbackStatus;
}
export interface ISetFeedbackSent {
  type: EFeedbackActionTypes.setFeedbackSent;
}
export interface IFeedbackSentPopupOpened {
  type: EFeedbackActionTypes.openFeedbackSentPopup;
}
export interface IFeedbackSentPopupClosed {
  type: EFeedbackActionTypes.closeFeedbackSentPopup;
}

export function openFeedbackPopup(): IFeedbackPopupOpened {
  return {
    type: EFeedbackActionTypes.openFeedbackPopup,
  };
}

export function closeFeedbackPopup(): IFeedbackPopupClosed {
  return {
    type: EFeedbackActionTypes.closeFeedbackPopup,
  };
}

export function openFeedbackComplaintPopup(): IFeedbackComplaintPopupOpened {
  return {
    type: EFeedbackActionTypes.openFeedbackComplaintPopup,
  };
}

export function closeFeedbackComplaintPopup(): IFeedbackComplaintPopupClosed {
  return {
    type: EFeedbackActionTypes.closeFeedbackComplaintPopup,
  };
}

export function showComplaints(): IThunkActionCreator {
  return dispatch => {
    dispatch(closeFeedbackPopup());
    dispatch(openFeedbackComplaintPopup());
  };
}

export function openForm(): IThunkActionCreator {
  return dispatch => {
    dispatch(closeFeedbackPopup());
    dispatch(openFeedbackFormPopup());
  };
}

export function openFeedbackFormPopup(): IFeedbackFormPopupOpened {
  return {
    type: EFeedbackActionTypes.openFeedbackFormPopup,
  };
}

export function closeFeedbackFormPopup(): IFeedbackFormPopupClosed {
  return {
    type: EFeedbackActionTypes.closeFeedbackFormPopup,
  };
}

export function addFeedbackFetchLoading(): IAddFeedbackStatusChanged {
  return {
    status: EAddFeedbackStatus.Loading,
    type: EFeedbackActionTypes.addFeedbackStatusChanged,
  };
}

export function addFeedbackFetchSucceed(): IAddFeedbackStatusChanged {
  return {
    status: EAddFeedbackStatus.Succeed,
    type: EFeedbackActionTypes.addFeedbackStatusChanged,
  };
}

export function addFeedbackFetchFailed(): IAddFeedbackStatusChanged {
  return {
    status: EAddFeedbackStatus.Failed,
    type: EFeedbackActionTypes.addFeedbackStatusChanged,
  };
}

export function setFeedbackSent(): ISetFeedbackSent {
  return {
    type: EFeedbackActionTypes.setFeedbackSent,
  };
}

export function openFeedbackSentPopup(): IFeedbackSentPopupOpened {
  return {
    type: EFeedbackActionTypes.openFeedbackSentPopup,
  };
}

export function closeFeedbackSentPopup(): IFeedbackSentPopupClosed {
  return {
    type: EFeedbackActionTypes.closeFeedbackSentPopup,
  };
}

export function addFeedback(comment: string): IThunkActionCreator {
  return (dispatch, getState, context) => {
    const {
      offerData: {
        offer: { id },
      },
    } = getState();

    dispatch(addFeedbackFetchLoading());

    return apiAddFeedback(context.httpApi, { offerId: id, comment })
      .then(response => {
        if (response.statusCode === 200) {
          dispatch(addFeedbackFetchSucceed());
          dispatch(closeFeedbackFormPopup());
          dispatch(setFeedbackSent());
          dispatch(openFeedbackSentPopup());
        } else {
          dispatch(addFeedbackFetchFailed());
          dispatch(closeFeedbackFormPopup());
          dispatch(setFeedbackSent());
          dispatch(openFeedbackSentPopup());
        }
      })
      .catch(error => {
        context.logger.error(error);

        dispatch(addFeedbackFetchFailed());
        dispatch(closeFeedbackFormPopup());
        dispatch(setFeedbackSent());
        dispatch(openFeedbackSentPopup());
      });
  };
}
