import loadable from '@loadable/component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { isRentDailySelector } from 'shared/selectors/isRentDaily';
import { isCommercialSelector } from 'shared/selectors/offerData/offer/isCommercialSelector';
import { isNewbuildingFromDeveloperSelector } from 'shared/selectors/offerData/offer/isNewbuildingFromDeveloperSelector';
import { isSuburbanSelector } from 'shared/selectors/suburban/isSuburbanSelector';

const NewbuildingPageContainerLoadable = loadable(
  () => import('shared/containers/NewbuildingPageContainer/NewbuildingPageContainer'),
);
const CommercialPageContainerLoadable = loadable(
  () => import('shared/containers/CommercialPageContainer/CommercialPageContainer'),
);
const SuburbanPageContainerLoadable = loadable(
  () => import('shared/containers/SuburbanPageContainer/SuburbanPageContainer'),
);
const DailyRentPageContainerLoadable = loadable(
  () => import('shared/containers/DailyRentPageContainer/DailyRentPageContainer'),
);
const FlatPageContainerLoadable = loadable(() => import('shared/containers/FlatPageContainer/FlatPageContainer'));

export const PageContainer = () => {
  const isNewbuildingFromDeveloper = useSelector(isNewbuildingFromDeveloperSelector);
  const isCommercial = useSelector(isCommercialSelector);
  const isRentDaily = useSelector(isRentDailySelector);
  const isSuburban = useSelector(isSuburbanSelector);

  if (isCommercial) {
    return <CommercialPageContainerLoadable />;
  }

  if (isNewbuildingFromDeveloper) {
    return <NewbuildingPageContainerLoadable />;
  }

  if (isRentDaily) {
    return <DailyRentPageContainerLoadable />;
  }

  if (isSuburban) {
    return <SuburbanPageContainerLoadable />;
  }

  return <FlatPageContainerLoadable />;
};
