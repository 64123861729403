import { setCookie } from '@cian/utils/lib/browser/cookie';
import { Dispatch } from '@reduxjs/toolkit';

import { IThunkActionCreator } from '.';

export interface IFavoriteMotivationState {
  isActive: boolean;
  isShows: boolean;
}

interface IFavoriteMotivationShowAction {
  type: 'IFavoriteMotivationShow';
}

export function showFavoriteMotivation(): IThunkActionCreator {
  return (dispatch: Dispatch<TFavoriteMotivationActions>) => {
    setCookie('offer_card-favorite_motivation', 'meow', {
      secure: false,
    });

    dispatch({
      type: 'IFavoriteMotivationShow',
    });
  };
}

interface IFavoriteMotivationHideAction {
  type: 'IFavoriteMotivationHide';
}

export function hideFavoriteMotivation(): IFavoriteMotivationHideAction {
  return {
    type: 'IFavoriteMotivationHide',
  };
}

export type TFavoriteMotivationActions = IFavoriteMotivationShowAction | IFavoriteMotivationHideAction;

const defaultState: IFavoriteMotivationState = {
  isActive: true,
  isShows: false,
};

export function favoriteMotivationReducer(
  state: IFavoriteMotivationState,
  action: TFavoriteMotivationActions,
): IFavoriteMotivationState {
  switch (action.type) {
    case 'IFavoriteMotivationShow':
      return {
        ...state,
        isActive: false,
        isShows: true,
      };

    case 'IFavoriteMotivationHide':
      return {
        ...state,
        isShows: false,
      };

    default:
      return state || defaultState;
  }
}
