import { ITypedReduxAction } from '../../types/redux';

import type { IThunkActionCreator } from '../../store';
import type { RootAction } from '../../types/redux/RootAction';

export enum EAlertType {
  Favorites = 'favorites',
  Chats = 'chats',
  Hide = 'hide',
}

interface IViewData {
  headingType: string | undefined;
}

export interface IRequestAuthenticationParameters {
  alert?: EAlertType;
  viewData?: IViewData;
  onSuccess?(): IThunkActionCreator | RootAction;
  onCloseNoAuth?(): IThunkActionCreator | RootAction;
}

export enum EAuthenticationActionType {
  Succeed = 'authentication/succeed',
  Cancelled = 'authentication/cancelled',
}

export type TAuthenticationSucceed = ITypedReduxAction<EAuthenticationActionType.Succeed>;
export type TAuthenticationCancelled = ITypedReduxAction<EAuthenticationActionType.Cancelled>;

export type TAuthenticationActions = TAuthenticationSucceed | TAuthenticationCancelled;
