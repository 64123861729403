import { IAgentAvailabilitySchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { getAgentAvailabilityTitle } from 'shared/utils/getAgentAvailabilityTitle';

import { IState } from '../../store';

export const getAgentAvailability = (state: IState): IAgentAvailabilitySchema | null => {
  const agentAvailability = state.offerData.agentAvailability;

  if (!agentAvailability) {
    return null;
  }

  const formattedTitle = getAgentAvailabilityTitle(agentAvailability);

  return { ...agentAvailability, title: formattedTitle };
};
