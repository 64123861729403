import { IState } from '../../store';
import { abUseExperimentsSelector, isAbUseExperimentsActive } from '../abUseExperiments';

export const NEWBUILDING_WINNER_EXPERIMENT = 'builders_q3_winners';

export const selectIsNewbuildingWinnerExperiment = (state: IState): boolean => {
  const experiments = abUseExperimentsSelector(state);
  const aGroup = isAbUseExperimentsActive(experiments, NEWBUILDING_WINNER_EXPERIMENT, 'A');
  const bGroup = isAbUseExperimentsActive(experiments, NEWBUILDING_WINNER_EXPERIMENT, 'B');

  return aGroup || bGroup;
};
