import { pathOr } from 'ramda';

import { IPhotoSchema } from 'shared/types/carousel';

import { IState } from '../../store';

export const getPhotos = (state: IState): IPhotoSchema[] => {
  const photos = pathOr([] as IPhotoSchema[], ['offerData', 'offer', 'photos'], state);

  return photos.filter(item => !item.isLayout);
};

export function getCountSelector(state: IState) {
  const photos = pathOr([] as IPhotoSchema[], ['offerData', 'offer', 'photos'], state);

  return photos.length;
}
