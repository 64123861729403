import { TDealType, TOfferType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { ISuccessResponse, sendComment } from '../../api/comments/send_comment';
import { IThunkActionCreator } from '../index';

export type TStatusFetch = 'none' | 'isLoad' | 'ok' | 'error';

export interface IComments {
  commentText: string;
  editing: boolean;
  isOpen: boolean;
  statusFetch: TStatusFetch;
  messageError: string;
}

export interface ICommentsOpen {
  type: 'ICommentsOpen';
}

export interface ICommentsClose {
  type: 'ICommentsClose';
}

export interface ICommentsFetching {
  type: 'ICommentsFetching';
  payload: {
    messageError: string;
    statusFetch: TStatusFetch;
  };
}

export interface ICommentsFetchSuccess {
  type: 'ICommentsFetchSuccess';
  payload: {
    statusFetch: TStatusFetch;
  };
}

export interface ICommentsFetchError {
  type: 'ICommentsFetchError';
  payload: {
    messageError: string;
    statusFetch: TStatusFetch;
  };
}

export interface ICommentsClearError {
  type: 'ICommentsClearError';
}

export interface ICommentsChangeEdditing {
  type: 'ICommentsChangeEdditing';
  payload: {
    editing: boolean;
  };
}

export interface ICommentsSetText {
  type: 'ICommentsSetText';
  payload: {
    commentText: string;
  };
}

export interface ICommentsInit {
  type: 'ICommentsInit';
  payload: {
    commentText: string;
    isOpen: boolean;
  };
}

export function changeEdditing(editing: boolean): ICommentsChangeEdditing {
  return {
    payload: {
      editing,
    },
    type: 'ICommentsChangeEdditing',
  };
}

export function openComments(): ICommentsOpen {
  return {
    type: 'ICommentsOpen',
  };
}

export function closeComments(): ICommentsClose {
  return {
    type: 'ICommentsClose',
  };
}

export function clearError(): ICommentsClearError {
  return {
    type: 'ICommentsClearError',
  };
}

export function setCommentText(commentText: string): ICommentsSetText {
  return {
    payload: {
      commentText,
    },
    type: 'ICommentsSetText',
  };
}

export function submitComment(
  host: string,
  text: string,
  action: 'save' | 'delete',
  oid: number,
  dealType: TDealType,
  offerType: TOfferType,
): IThunkActionCreator {
  return (dispatch, getState, context) => {
    dispatch({
      payload: {
        messageError: '',
        statusFetch: 'isLoad',
      },
      type: 'ICommentsFetching',
    });

    // csrfToken - передается пустым
    sendComment(context.custom.makeRequest, host, text, action, oid, dealType, offerType, '')
      .then((response: ISuccessResponse) => {
        if (response.status === 'ok') {
          dispatch({
            payload: {
              statusFetch: 'ok',
            },
            type: 'ICommentsFetchSuccess',
          });
          dispatch(changeEdditing(false));
        }
      })
      .catch(() => {
        dispatch({
          payload: {
            messageError: 'Не удалось сохранить комментарий. Попробуйте ещё раз.',
            statusFetch: 'error',
          },
          type: 'ICommentsFetchError',
        });
      });
  };
}

export type TCommentsActions =
  | ICommentsInit
  | ICommentsOpen
  | ICommentsClose
  | ICommentsFetching
  | ICommentsFetchSuccess
  | ICommentsFetchError
  | ICommentsClearError
  | ICommentsSetText
  | ICommentsChangeEdditing;

const initialState = {
  commentText: '',
  editing: false,
  isOpen: false,
  messageError: '',
  statusFetch: 'none' as const,
};

export function commentsReducer(state: IComments = initialState, action: TCommentsActions): IComments {
  switch (action.type) {
    case 'ICommentsInit':
      return {
        ...state,
        commentText: action.payload.commentText,
        isOpen: action.payload.isOpen,
      };

    case 'ICommentsOpen':
      return {
        ...state,
        isOpen: true,
      };

    case 'ICommentsClose':
      return {
        ...state,
        editing: false,
        isOpen: false,
        messageError: '',
        statusFetch: 'none',
      };

    case 'ICommentsFetching':
      return {
        ...state,
        messageError: '',
        statusFetch: action.payload.statusFetch,
      };

    case 'ICommentsFetchSuccess':
      return {
        ...state,
        statusFetch: action.payload.statusFetch,
      };

    case 'ICommentsFetchError':
      return {
        ...state,
        messageError: action.payload.messageError,
        statusFetch: action.payload.statusFetch,
      };

    case 'ICommentsClearError':
      return {
        ...state,
        messageError: '',
        statusFetch: 'none',
      };

    case 'ICommentsSetText':
      return {
        ...state,
        commentText: action.payload.commentText,
      };

    case 'ICommentsChangeEdditing':
      return {
        ...state,
        editing: action.payload.editing,
      };

    default:
      return state;
  }
}
