import { DynamicCalltrackingContext } from '@cian/frontend-dynamic-calltracking-component';
import { VisitedOffersProvider, IVisitedOffersProviderProps } from '@cian/frontend-visited-offers-widget';
import { Provider as MicrofrontendProvider } from '@cian/mf-react';
import { Store } from '@reduxjs/toolkit';
import * as React from 'react';
import { Provider } from 'react-redux';

import { NewbuildingCallbackWidgetProvider } from 'shared/containers/NewbuildingCallbackWidgetProvider';
import { PageContainer } from 'shared/containers/PageContainer';

import { ActionsCentersLoadable } from './internal/components/ActionsCenters/ActionsCentersLoadable';
import { IState } from '../../store';
import { IApplicationContext } from '../../types/applicationContext';
import { ApplicationContext } from '../ApplicationContext';
import { SaveSearchModalProvider } from '../SaveSearchModalProvider';
import { SubscriptionsContainer } from '../Subscriptions';

interface IApplicationContainerProps {
  context: IApplicationContext;
  reduxStore: Store<IState>;
  renderCallback: undefined | (() => void);
}

export const ApplicationContainer: React.FC<IApplicationContainerProps> = ({ context, reduxStore, renderCallback }) => {
  const { httpApi, logger, microfrontendsRegistry, config } = context;

  const { settings, enabled } = config.get<IVisitedOffersProviderProps>('audience.visitedOffers') || {};

  return (
    <div ref={renderCallback}>
      <Provider store={reduxStore}>
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <DynamicCalltrackingContext.Provider value={{ httpApi, logger }}>
          <ApplicationContext.Provider value={context}>
            <MicrofrontendProvider registry={microfrontendsRegistry}>
              <ActionsCentersLoadable />
              <VisitedOffersProvider settings={settings} enabled={enabled}>
                <SaveSearchModalProvider>
                  <NewbuildingCallbackWidgetProvider>
                    <PageContainer />
                    <SubscriptionsContainer reduxStore={reduxStore} />
                  </NewbuildingCallbackWidgetProvider>
                </SaveSearchModalProvider>
              </VisitedOffersProvider>
            </MicrofrontendProvider>
          </ApplicationContext.Provider>
        </DynamicCalltrackingContext.Provider>
      </Provider>
    </div>
  );
};
