import { init as initConfig } from '@cian/config/browser';
import { getEventBusIfDefined } from '@cian/event-bus';
import { initGlobalEventsLogs } from '@cian/events-log';
import { init as initHttpApi } from '@cian/http-api/browser';
import { init as initLogger } from '@cian/logger/browser';
import { init as initTelemetry } from '@cian/telemetry/browser';
import { init as initWeb } from '@cian/web/browser';

export function initDependencies() {
  const eventBus = getEventBusIfDefined();

  if (eventBus) {
    eventBus.emit('metrics-loader', {
      type: 'lock-initialization',
    });
  }

  initConfig();
  initLogger();
  initTelemetry();
  initHttpApi();
  initWeb();
  initGlobalEventsLogs();
}
