import { actions } from './actions';
import { ECurrentStep } from '../../renterForm/constants';
import { ISaveFormRequest } from '../../repositories/deal-rent-announcements/v1/save-form';

export interface IState {
  isOpen: boolean;
  isLoading: boolean;
  error: string | null;
  isSuccessModal: boolean;
  isFailureModal: boolean;
  isPrefetching: boolean;
  secondsToRetry: number;
  phoneConfirmed: boolean;
  isSendSMSLoading: boolean;
  currentStep: ECurrentStep;
  initialFormState: ISaveFormRequest;
}

export const initialState: IState = {
  isOpen: false,
  isLoading: false,
  error: null,
  isSuccessModal: false,
  isFailureModal: false,
  isPrefetching: false,
  phoneConfirmed: false,
  isSendSMSLoading: false,
  secondsToRetry: 60,
  currentStep: ECurrentStep.Onboarding,
  initialFormState: {
    personsCount: 1,
    personsInfo: '',
    childrenCount: 0,
    childrenInfo: [],
    petsCount: 0,
    petsInfo: '',
    comment: '',
    activityScope: '',
    name: '',
    isAgent: false,
    phone: '',
    smsCode: '',
  },
};

export function reducer(state: IState = initialState, action: actions): IState {
  switch (action.type) {
    case 'renterForm/open':
      return { ...state, isOpen: true, error: null };
    case 'renterForm/close':
      return {
        ...state,
        isOpen: false,
        initialFormState: initialState.initialFormState,
        currentStep: ECurrentStep.Onboarding,
      };
    case 'renterForm/openSuccess':
      return { ...state, isSuccessModal: true };
    case 'renterForm/closeSuccess':
      return { ...state, isSuccessModal: false };
    case 'renterForm/openFailure':
      return { ...state, isFailureModal: true };
    case 'renterForm/closeFailure':
      return { ...state, isFailureModal: false };
    case 'renterForm/updateForm':
      return { ...state, initialFormState: action.payload.form };
    case 'renterForm/updateCurrentStep':
      return { ...state, currentStep: action.payload };
    case 'renterForm/saveFormStart':
      return { ...state, isLoading: true, error: null };
    case 'renterForm/saveFormSuccess':
      return { ...state, isLoading: false };
    case 'renterForm/saveFormError':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        currentStep: state.phoneConfirmed ? ECurrentStep.Third : ECurrentStep.Fourth,
      };
    case 'renterForm/prefetchRenterFormStateStart':
      return { ...state, isPrefetching: true, phoneConfirmed: false };
    case 'renterForm/prefetchRenterFormStateSuccess':
      return {
        ...state,
        isPrefetching: false,
        initialFormState: { ...state.initialFormState, ...action.payload.form },
        phoneConfirmed: action.payload.phoneConfirmed,
      };
    case 'renterForm/prefetchRenterFormStateError':
      return { ...state, isPrefetching: false };
    case 'renterForm/sendSMSStart':
      return { ...state, isSendSMSLoading: true, phoneConfirmed: false, secondsToRetry: 60 };
    case 'renterForm/sendSMSSuccess':
      return {
        ...state,
        secondsToRetry: action.payload,
        phoneConfirmed: false,
        isSendSMSLoading: false,
        error: null,
        currentStep: ECurrentStep.Fourth,
      };
    case 'renterForm/sendSMSAlreadyConfirmed':
      return {
        ...state,
        phoneConfirmed: true,
        isSendSMSLoading: false,
        currentStep: ECurrentStep.Fourth,
      };
    case 'renterForm/sendSMSError':
      return { ...state, isLoading: false, error: action.payload, phoneConfirmed: false, isSendSMSLoading: false };
    case 'renterForm/changePhoneConfirmed':
      return { ...state, phoneConfirmed: action.payload };
    default:
      return state;
  }
}
