import { addDays, differenceInCalendarDays, format, max, min, parse } from 'date-fns';

import { ICalendarError, TDaysAvailability, TDayStatus, TSelectedRange } from 'shared/types/bookingCalendar';

export const DEFAULT_INPUT_TEXT = 'Заезд – Выезд';

export const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

// Каждая первая дата в занятых диапозонах это дата в которую гости заезжают.
// Но эта дата может быть выбрана как дата выезда другого гостя, поэтому она не заблокирована для выбора.
// Соответственно этот день будет иметь статус "только выезд", а остальные занято/свободно
// 0 – занято, 1 – свободно, 2 - только выезд
export const getDayStatus = (availabilityArray: TDaysAvailability, date: Date): TDayStatus => {
  const today = new Date();
  const index = differenceInCalendarDays(date, today);

  if (index < 0) {
    return 0;
  }

  // если календарь проверяет даты за пределами проверенных на доступность, возвращать их доступными
  if (availabilityArray[index] === undefined || index < 0) {
    return 1;
  }

  // 2 - только выезд
  if (!availabilityArray[index] && !!availabilityArray[index - 1]) {
    return 2;
  }

  // 0 – занято, 1 – свободно
  return availabilityArray[index];
};

/** Ограничивает диапазон ближайшей занятой датой, если она попадается внутри этого диапазона */
export const getNormalizedEndDate = (
  minDate: Date,
  startDate: Date,
  endDate: Date,
  daysAvailability: TDaysAvailability,
) => {
  /** Количество дней от сегодняшнего до начальной даты диапазона */
  const index = Math.abs(differenceInCalendarDays(minDate, startDate));

  /** Количество дней в выбранном диапазоне */
  const daysInRange = Math.abs(differenceInCalendarDays(startDate, endDate));

  /** Массив доступности, начиная со следующего дня после выбранной начальной даты */
  // Может показаться что сдесь должжно быть index + 1, но так задумано
  // Так как каждая первая дата в занятых диапозонах это дата в которую гости заезжают.
  // Но эта дата может быть выбрана как дата выезда другого гостя, поэтому она не заблокирована для выбора.
  const slicedAvailability = daysAvailability.slice(index);

  /** Количество дней от начальной даты диапазона до следующей недоступной даты */
  const daysUntilUnavailable = slicedAvailability.findIndex(d => !d);

  let normalizedEndDate = endDate;
  if (daysUntilUnavailable !== -1 && daysInRange > daysUntilUnavailable) {
    normalizedEndDate = addDays(startDate, daysUntilUnavailable);
  }

  return normalizedEndDate;
};

export enum ValidationRuleTitle {
  NO_RANGE = 'noRange',
  MIN_RANGE = 'minRange',
  MAX_RANGE = 'maxRange',
  ONLY_CHECKOUT = 'onlyCheckout',
}

const ValidationRulesErrorMap: Record<ValidationRuleTitle, string> = {
  [ValidationRuleTitle.NO_RANGE]: 'Даты не выбраны',
  [ValidationRuleTitle.MIN_RANGE]: 'Выберите разные дни заезда и выезда',
  [ValidationRuleTitle.MAX_RANGE]: 'Максимум 30 ночей',
  [ValidationRuleTitle.ONLY_CHECKOUT]: 'Доступен только выезд',
};

// Тут важен порядок тк одновременно может быть показано только одно сообщение, будет показано первое найденое
const validationRules: [ValidationRuleTitle, (selection: TSelectedRange) => boolean][] = [
  [
    ValidationRuleTitle.ONLY_CHECKOUT,
    (selection: TSelectedRange) => {
      const { fromValue, daysAvailability } = selection;
      if (!fromValue) {
        return false;
      }

      const index = differenceInCalendarDays(fromValue, new Date());

      // Каждая первая дата в занятых диапозонах это дата в которую гости заезжают.
      // Но эта дата может быть выбрана как дата выезда другого гостя, поэтому она не заблокирована для выбора.
      // Однако выбор ее первой датой является некорректным.
      return !!daysAvailability[index];
    },
  ],
  [
    ValidationRuleTitle.NO_RANGE,
    (selection: TSelectedRange) => {
      const { fromValue, toValue } = selection;

      return !!fromValue || !!toValue;
    },
  ],
  [
    ValidationRuleTitle.MIN_RANGE,
    (selection: TSelectedRange) => {
      const { fromValue, toValue } = selection;
      if (!fromValue || !toValue) {
        return false;
      }

      return Math.abs(differenceInCalendarDays(fromValue, toValue)) > 0;
    },
  ],
  [
    ValidationRuleTitle.MAX_RANGE,
    (selection: TSelectedRange) => {
      const { fromValue, toValue } = selection;
      if (!fromValue || !toValue) {
        return false;
      }

      return Math.abs(differenceInCalendarDays(fromValue, toValue)) <= 30;
    },
  ],
];

const blockers = [ValidationRuleTitle.NO_RANGE, ValidationRuleTitle.MIN_RANGE, ValidationRuleTitle.MAX_RANGE];

export const getValidationErrors = (selection: TSelectedRange): ICalendarError[] => {
  const invalidRules = validationRules.filter(([, predicate]) => !predicate(selection));
  if (!invalidRules || !invalidRules.length) {
    return [];
  }

  return invalidRules.map(([errorType]) => ({
    isBlocker: blockers.includes(errorType),
    errorText: ValidationRulesErrorMap[errorType],
  }));
};

interface IUpdateDailyrentUrlParameters {
  currentUrl: string;
  dates?: { checkin: string; checkout: string };
  guestsCount?: number;
}

export const updateDailyrentUrlParameters = ({
  currentUrl,
  dates,
  guestsCount,
}: IUpdateDailyrentUrlParameters): string => {
  const url = new URL(currentUrl);

  if (dates) {
    url.searchParams.set('checkin', dates.checkin);
    url.searchParams.set('checkout', dates.checkout);
  }

  if (guestsCount && guestsCount > 1) {
    url.searchParams.set('min_beds', String(guestsCount));
  } else {
    url.searchParams.delete('min_beds');
  }

  return url.toString();
};

type TDailyrentQueryParams = 'checkin' | 'checkout' | 'min_beds';

export const deleteDailyrentQueryParams = (currentUrl: string, paramsToDelete: TDailyrentQueryParams[]) => {
  const url = new URL(currentUrl);

  paramsToDelete.forEach(p => {
    url.searchParams.delete(p);
  });

  return url.toString();
};

export const getDailyrentQueryParams = (currentUrl: string) => {
  const url = new URL(currentUrl);

  return {
    checkin: url.searchParams.get('checkin'),
    checkout: url.searchParams.get('checkout'),
    guestsCount: url.searchParams.get('min_beds'),
  };
};

export const getNormalizedInitialDates = (
  minDate: Date,
  dates: { from?: string; to?: string },
  daysAvailability: TDaysAvailability,
) => {
  let startDate = dates.from ? parse(dates.from, 'yyyy-MM-dd', new Date()) : null;
  let endDate = dates.to ? parse(dates.to, 'yyyy-MM-dd', new Date()) : null;

  if (!startDate || !endDate) {
    return { normalizedInitialStart: null, normalizedInitialEnd: null };
  }

  startDate = min([startDate, endDate]);
  const latestDate = max([startDate, endDate]);
  endDate = getNormalizedEndDate(minDate, startDate, latestDate, daysAvailability);

  return { normalizedInitialStart: startDate, normalizedInitialEnd: endDate };
};
