import { ca } from '@cian/analytics';

export const trackCalendarInputShow = (offerId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'DailyrentCalendar',
    action: 'show_sopr',
    label: 'Input',
    page: {
      pageType: 'Card',
    },
    products: [{ id: offerId }],
  });
};
export const trackCalendarInputClick = (offerId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'DailyrentCalendar',
    action: 'click_sopr',
    label: 'Input',
    page: {
      pageType: 'Card',
    },
    products: [{ id: offerId }],
  });
};
export const trackMinimumDurationHint = (offerId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'DailyrentCalendar',
    action: 'show_sopr',
    label: 'HintMinDuration',
    page: {
      pageType: 'Card',
    },
    products: [{ id: offerId }],
  });
};
export const trackCalendarDatesSubmit = (offerId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'DailyrentCalendar',
    action: 'click',
    label: 'ConfirmDates',
    page: {
      pageType: 'Card',
    },
    products: [{ id: offerId }],
  });
};
export const trackCalendarReset = (offerId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'DailyrentCalendar',
    action: 'click',
    label: 'ResetDates',
    page: {
      pageType: 'Card',
    },
    products: [{ id: offerId }],
  });
};
