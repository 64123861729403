/* Длина телефона с символами +/-/space */
const LENGTH_PHONE_DIGITS = 11;
const EMAIL_REGX =
  /^([0-9a-zA-Z](([+\-_.][0-9a-zA-Z]*)|(\.[0-9a-zA-Z]+))*)+@(([0-9a-zA-Zа-яА-Я][-\w]*[0-9a-zA-Zа-яА-Я]*\.)+[a-zA-Z0-9а-яА-Я]{2,17})$/;
const FAKE_EMAIL_REGX = /@social\.cian\.ru$/;

export function isEmail(email: string): boolean {
  return EMAIL_REGX.test(email);
}

export function isFakeEmail(email: string): boolean {
  return FAKE_EMAIL_REGX.test(email);
}

export function isPhoneNumber(phoneNumber: string): boolean {
  const phoneDigits = phoneNumber.replace(/[^0-9.]/g, '');

  return phoneDigits.length === LENGTH_PHONE_DIGITS;
}
