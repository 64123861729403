import { EActionType } from 'shared/types/dailyrentActionButtonsType';
import { RootAction } from 'shared/types/redux/RootAction';

import { EDailyrentActionTypeTypes } from '../../actions/dailyrentActionType';

export type TDailyrentActionTypeState = EActionType | null;

/**
 * С бэка приходит actionType – от него зависит, какие кнопки отрисуются в карточке.
 * Если пришел actionType = 'contact', нам нельзя менять его на другой. Значение actionType
 * может поменяться только при взаимодействии с календарем. Если это взаимодействие происходит,
 * то с бэка пришел параметр showBookingCalendar = true. Если actionType = 'contact' и showBookingCalendar = true,
 * то, скорее всего, это объявка с bnovo-календарем, и её нельзя забронировать.
 * @param state
 * @param action
 */
export function dailyrentActionTypeReducer(
  state: TDailyrentActionTypeState = null,
  action: RootAction,
): TDailyrentActionTypeState {
  if (state === EActionType.Contact) {
    return state;
  }

  switch (action.type) {
    case EDailyrentActionTypeTypes.SetActionTypeFromOffer: {
      return action.payload.offer.dailyrent?.actionType || null;
    }

    case EDailyrentActionTypeTypes.SetActionType: {
      return action.payload;
    }

    default:
      return state;
  }
}
